import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { VpnKey, PowerSettingsNewOutlined } from '@mui/icons-material'

import { useAlert } from '../../components/Alert'
import ModalInfo from '../../components/ModalInfo'
import ButtonLight from '../../components/Button/ButtonLight'
import ButtonGradient from '../../components/Button/ButtonGradient'
import useUserClient from '../../../clients/UserClient/useUserClient'
import useSecurity from '../../hooks/useSecurity'

const useStyles = makeStyles((theme) => ({
  profilesMenu: {
    padding: 0,
    borderBottom: '1px solid #8080805e',
    '& .MuiList-root': {
      padding: 0,
      '& .MuiListItem-root': {
        paddingBlock: 8,
        paddingInline: 30,
      },
    },
    '& .MuiListItemAvatar-root': {
      minWidth: 'inherit',
      '& .MuiAvatar-root': {
        width: '45px',
        height: '45px',
      },
    },
    '& .MuiTypography-subtitle1': {
      fontSize: 14,
      display: 'flex',
    },
    '& .MuiTypography-subtitle2': {
      fontWeight: 'bold',
    },
    '& .MuiListItemText-multiline': {
      margin: 0,
    },
  },
  buttonsBox: {
    '& > .MuiBox-root': {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: '1 1 33.3%',
        justifyContent: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        flex: '1 1 100%',
      },
    },
  },
  buttonMenu: {
    border: 'none',
    width: '100%',
    borderRadius: 0,
    fontWeight: 600,
    '& .MuiButton-label': {
      justifyContent: 'left',
    },
  },
  buttonPwd: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  iconForm: {
    color: theme.palette.text.secondary,
    fontSize: '22px',
  },
  modal: {
    height: 400,
    marginTop: 100,
    '& .MuiPaper-root': {
      height: 'initial !important',
      [theme.breakpoints.up('md')]: {
        width: 600,
      },
    },
  },
}))

const UserOptionMenu = ({ logout }) => {
  const classes = useStyles()
  const userClient = useUserClient()
  const { addMsgSuccess, addMsgDanger } = useAlert()
  const {
    getUser,
  } = useSecurity()
  const user = getUser()
  const userEmail = user?.email
  const [openModalChangePassword, setOpenModalChangePassword] = useState(false)

  const DEFAULT_VALUES_RESET_PASSWORD = {
    email: userEmail,
    unlock: true,
    application: process.env.REACT_APP_WINGS_APPLICATION,
  }

  return (
    <>
      {openModalChangePassword && (
        <ModalInfo
          close={false}
          open={openModalChangePassword}
          className={classes.modal}
        >
          <Box>
            <Box mb={7}>
              <Typography align="center" variant="h5">Alterar Senha</Typography>
            </Box>

            <Box mb={6}>
              <Typography color="primary" align="center">
                Tem certeza que deseja alterar sua senha?
              </Typography>
            </Box>

            <Box>
              <Grid container spacing={2} justifyContent="space-around" className={classes.buttonsStep}>
                <Grid item xs={12} sm={1}>
                  {' '}
                </Grid>

                <Grid item xs={12} sm={4}>
                  <ButtonGradient onClick={() => {
                    userClient().requestPassword(DEFAULT_VALUES_RESET_PASSWORD)
                      .then(() => {
                        addMsgSuccess({
                          message: 'E-mail de alteração de senha enviado.',
                        })
                        setOpenModalChangePassword(false)
                      }, (error) => {
                        if (error) {
                          const { data: err } = error
                          addMsgDanger({ ...err })
                        }
                      })
                  }}
                  >
                    Sim
                  </ButtonGradient>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <ButtonLight
                    onClick={() => {
                      setOpenModalChangePassword(false)
                    }}
                  >
                    Não
                  </ButtonLight>
                </Grid>
                <Grid item xs={12} sm={1}>
                  {' '}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </ModalInfo>
      )}

      <Paper>
        <Box className={classes.buttonsBox}>
          <Box borderBottom="1px solid #8080803b">
            <Button
              className={`${classes.buttonMenu} ${classes.buttonPwd}`}
              title="Alterar Senha"
              onClick={() => {
                setOpenModalChangePassword(true)
              }}
            >
              <Box mr={1}>
                <VpnKey className={classes.iconForm} />
              </Box>
              Alterar Senha
            </Button>
          </Box>

          <Box>
            <Button
              title="Sair"
              onClick={logout}
              className={`${classes.buttonMenu} ${classes.buttonPwd}`}
            >
              <Box mr={1}>
                <PowerSettingsNewOutlined className={classes.iconForm} />
              </Box>
              Sair da conta
            </Button>
          </Box>
        </Box>
      </Paper>
    </>
  )
}

UserOptionMenu.propTypes = {
  logout: PropTypes.func.isRequired,
}

export default UserOptionMenu
