import React from 'react'
import PropTypes from 'prop-types'

import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import CardContent from '@mui/material/CardContent'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  main: {
    top: -150,
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      paddingLeft: theme.spacing(8),
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      zIndex: 2,
    },
  },
  container: {
    width: '100%',
    height: '100%',
    flex: '1 1 100%',
  },
  card: {
    minHeight: '100%',
    width: '100%',
    height: '100%',
    borderRadius: 0,
    boxShadow: 'none',
    background: 'transparent',
    // [theme.breakpoints.up('md')]: {
    //   padding: theme.spacing(2, 3),
    // },
  },
  cardBody: {
    width: '100%',
    paddingInline: 0,
    minHeight: '100%',
  },
  btn: {
    width: '100%',
  },
}))

const Main = ({ children }) => {
  const classes = useStyles()

  return (
    <Container maxWidth="xl" className={classes.main}>
      <Grid container className={classes.container}>
        <Card className={classes.card}>
          <CardContent className={classes.cardBody}>
            {children}
          </CardContent>
        </Card>
      </Grid>
    </Container>
  )
}

Main.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Main
