import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Routes from './routes'

import Loader from './shared/components/Loader'
import AlertProvider from './shared/components/Alert'
import ScreenLoaderContext from './shared/contexts/ScreenLoaderContext'

import './helpers/yup'
import './assets/style/global.scss'
import AxiosSetting from './helpers/axios'

function App() {
  const navigate = useNavigate()
  const { Provider: ScreenLoaderProvider } = ScreenLoaderContext

  const [loading, setLoading] = useState(false)
  const [showLoading, setShowLoading] = useState(true)

  const handleError = useMemo(
    () => ({
      401: () => {
        // setTimeout(() => signout())
      },
      403: (data) => {
        const { config } = data

        // Case user unauthorized access userInfo.
        const { url = '' } = config

        if (url === '/user/find' || url === '/client/findByUser') {
          // signout('/unauthorized')
          return
        }

        // Case user unauthorized access by roles.
        navigate('/error/403')
      },
    }),
    [navigate],
  )

  return (
    <>
      <AxiosSetting
        handleError={handleError}
        handleShowLoading={setShowLoading}
        onStartRequest={() => {
          if (showLoading) {
            setLoading(true)
          }
        }}
        onStopRequest={() => setLoading(false)}
      />
      <Loader show={loading} setLoading={setLoading} />
      <AlertProvider />
      <ScreenLoaderProvider value={{ screenLoaded: !loading }}>
        <Routes />
      </ScreenLoaderProvider>
    </>
  )
}

export default App
