import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'

import makeStyles from '@mui/styles/makeStyles'

import TitlePage from './TitlePage'
import { useTemplateContext } from '../../contexts/TemplateContext'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    backgroundSize: 'cover',
    backgroundPosition: 'center 25%',
    '& .MuiContainer-root': {
      display: 'flex',
      height: theme.sizes.header,
    },
    '& .MuiTypography-h4': {
      fontSize: '2.5rem',
      color: theme.palette.primary.contrastText,
    },
  },
  rootForRegistrationForm: {
    height: 150,
    color: theme.palette.primary.contrastText,
    backgroundSize: 'cover',
    backgroundPosition: 'center 25%',
    '& .MuiContainer-root': {
      display: 'flex',
      height: theme.sizes.header,
    },
    '& .MuiTypography-h4': {
      fontSize: '2.5rem',
      color: theme.palette.primary.contrastText,
    },
  },
  bgWhite: {
    backgroundColor: '#F6F6F6',
  },
  main: {
    height: 270,
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(0, 8),
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 12),
    },
  },
  containerMain: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  title: {
    display: 'flex',
    width: 160,
    '& img': {
      maxWidth: '100%',
    },
  },
  containerMenu: (props) => {
    const styles = {
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    }

    if (props.isError) {
      styles.zIndex = 1
    }

    return styles
  },
  clientLogo: {
    maxHeight: 50,
    maxWidth: 180,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}))

const Header = (props) => {
  const {
    crumbs,
    bgImage,
    isError,
  } = props

  const theme = useTheme()
  const classes = useStyles({ isError })
  const { titlePage } = useTemplateContext()

  return (
    <header
      className={clsx(titlePage === 'Cadastro' ? classes.rootForRegistrationForm : classes.root, isError && classes.bgWhite)}
      style={bgImage && {
        animation: 'fadein 0.5s',
        backgroundImage: `linear-gradient(to left, #19BDDDCC, ${theme.palette.primary.main}CC), url(${bgImage})`,
      }}
    >
      <Container maxWidth="xl" className={classes.main}>
        <Grid container className={classes.containerMain}>
          <Grid
            md
            item
            xs="auto"
            sm="auto"
            className={classes.containerMenu}
          >
            <Box
              hidden={isError}
              className={classes.headerMenu}
            >
              <TitlePage crumbs={crumbs} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </header>
  )
}

Header.propTypes = {
  bgImage: PropTypes.any.isRequired,
  crumbs: PropTypes.array.isRequired,
  isError: PropTypes.bool.isRequired,
}

export default Header
