import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import ViewerDocument from '../../../shared/components/ViewerDocument'
import { useAlert } from '../../../shared/components/Alert'
import usePolicyClient from '../../../clients/PolicyClient/usePolicyClient'

const AllTicketViewer = () => {
  const policyClient = usePolicyClient()

  const { documentNumber } = useParams()
  const { addMsgDanger } = useAlert()
  const navigate = useNavigate()

  const [draftData, setDocumentData] = useState('')

  useEffect(() => {
    policyClient().getAllTickets(documentNumber).then((response) => {
      setDocumentData(response.data)
    }, () => {
      addMsgDanger({
        title: 'Não foi possível buscar o documento no momento',
        message: 'tente novamente mais tarde ou contate o Administrador.',
        action: () => navigate('/apolices'),
      })
    })
  }, [documentNumber, addMsgDanger, policyClient, navigate])

  return (
    <ViewerDocument documentBase64={draftData} />
  )
}

export default AllTicketViewer
