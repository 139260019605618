import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'

import makeStyles from '@mui/styles/makeStyles'

import useStyles from './useStyles'

const useCustomStyle = makeStyles((theme) => ({
  btn: {
    color: '#fff',
    paddingInline: 20,
    textTransform: 'initial',
    backgroundImage: `linear-gradient(90deg, #19B8DA, ${theme.palette.primary.main} 50%)`,
    '&:hover': {
      textDecoration: 'unset',
    },
    '&.Mui-disabled': {
      background: '#B1B3C9',
      color: '#3F4159',
    },
  },
}))

const ButtonGradient = (props) => {
  const { children } = props
  const classes = useStyles()
  const classesCustom = useCustomStyle()

  return (
    <Button
      className={clsx(classes.btn, classesCustom.btn)}
      {...props}
    >
      {children}
    </Button>
  )
}

ButtonGradient.propTypes = {
  children: PropTypes.any,
}

ButtonGradient.defaultProps = {
  children: undefined,
}

export default ButtonGradient
