import React, { useContext } from 'react'

const TemplateContext = React.createContext()

export const useTemplateContext = () => {
  const {
    titlePage,
    mobileOpen,
    setTitlePage,
  } = useContext(TemplateContext)

  return {
    titlePage,
    mobileOpen,
    setTitlePage,
  }
}

export default TemplateContext
