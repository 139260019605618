import makeStyles from '@mui/styles/makeStyles'

export default makeStyles((theme) => ({
  socialNetworks: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    '& .MuiGrid-root': {
      padding: '0',
      width: 'auto',
    },
  },
  form: {
    width: '100%',
    maxWidth: 390,
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'center',
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(3),
    },
    marginTop: theme.spacing(7),
  },
  link: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  titleContainer: {
    display: 'flex',
    direction: 'column',
    alignItems: 'center',
    '& img': {
      marginBottom: theme.spacing(1),
    },
  },
  title: {
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: 40,
    color: theme.palette.primary.main,
  },
  header: {
    display: 'flex',
    marginBottom: '3em',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logo: {
    flex: 'auto',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    '& img': {
      width: 180,
      maxWidth: '100%',
    },
    '@media (max-width: 599px)': {
      marginBottom: '3em',
    },
  },
  labelInfo: {
    fontSize: 14,
    marginTop: theme.spacing(4),
  },
  labelAlert: {
    fontSize: 13,
    marginBlock: 2,
    color: theme.palette.error.main,
  },
  containerForm: {
    '& .MuiFormLabel-root': {
      color: theme.palette.text.quaternary,
      fontWeight: 'bold',
      lineHeight: 2,
    },
  },
}))
