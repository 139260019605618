import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import ViewerDocument from '../../../shared/components/ViewerDocument'

import useDebtsClient from '../../../clients/DebtsClient/useDebtsClient'

const TicketViewer = () => {
  const debtsClient = useDebtsClient()

  const { billingId } = useParams()

  const [draftData, setDocumentData] = useState('')

  useEffect(() => {
    debtsClient().getInvoiceDocument({
      billingId,
      base64: true,
    }).then((response) => {
      setDocumentData(response.data)
    })
  }, [billingId, debtsClient])

  return (
    <ViewerDocument documentBase64={draftData} />
  )
}

export default TicketViewer
