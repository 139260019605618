import React, { useContext } from 'react'

const RegistrationForm = React.createContext()

export const useRegistrationForm = () => {
  const {
    actionNext,
    actionPrevious,
    onNext,
    onRegister,
    policy,
    setPolicy,
    addMsgError,
    isFormikChanged,
    setFormikChanged,
    confirmation,
    setConfirmation,
    formEmpty,
    setFormEmpty,
  } = useContext(RegistrationForm)

  return {
    actionNext,
    actionPrevious,
    onNext,
    onRegister,
    policy,
    setPolicy,
    addMsgError,
    isFormikChanged,
    setFormikChanged,
    confirmation,
    setConfirmation,
    formEmpty,
    setFormEmpty,
  }
}

export default RegistrationForm
