const RegistrationData = (policy) => ({
  codigo: policy?.policyData?.codigo ?? null,
  policyUploadId: policy?.policyData?.policyUploadId ?? null,
  corretora: {
    cnpj: policy?.policyData?.brokerCnpj ?? null,
    nome: policy?.policyData?.brokerName ?? null,
  },
  seguradora: {
    id: policy?.policyData?.insuranceCompanyId ?? null,
    name: policy?.policyData?.insuranceCompanyName ?? null,
  },
  modalidade: {
    codigoModalidade: policy?.policyData?.codeModality ?? null,
    productKey: policy?.policyData?.productKey ?? null,
  },
  dataEmissao: policy?.policyData?.dateOfIssue ?? null,
  inicioVigencia: policy?.policyData?.startDateValidity ?? null,
  terminoVigencia: policy?.policyData?.endDateValidity ?? null,
  tipoDocumento: policy?.policyData?.typeDocument ?? null,
  numeroDocumento: policy?.policyData?.policyNumber ?? null,
  numeroPrincipal: policy?.policyData?.mainPolicyNumber ?? null,
  numeroEndossada: policy?.policyData?.endorsedNumber ?? null,
  tipoEndosso: {
    numeroTipoEndosso: policy?.policyData?.endorsementTypeId ?? null,
    descricaoEndosso: policy?.policyData?.endorsementTypeText ?? null,
  },
  segurado: {
    nomeBeneficiario: policy?.insured?.name ?? null,
    cnpjBeneficiario: policy?.insured?.document ?? null,
  },
  tomador: {
    clienteCpfCnpj: policy?.policyHolder?.document ?? null,
    clienteNome: policy?.policyHolder?.name ?? null,
  },
  quantidadeParcelas: policy?.payment?.installmentsNumber ?? null,
  premioTotal: policy?.payment?.totalPrize ?? null,
  policyLmg: policy?.policyData?.guaranteeValue ?? null,
  tipoPagamentoPremio: policy?.payment?.premiumPaymentType ?? '',
})

export default RegistrationData
