import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Icon from '@mui/material/Icon'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import Backdrop from '@mui/material/Backdrop'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1),
    height: `calc(100% - ${theme.spacing(2)})`,
  },
  paper: {
    maxWidth: '90%',
    maxHeight: '100%',
    height: '100%',
    position: 'relative',
    paddingTop: theme.spacing(5),
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '96%',
    },

    '& .MuiPaper-root': {
      maxWidth: '100% !important',
      maxHeight: '100%',
      height: '100%',
      borderRadius: 0,
    },
  },

  close: {
    cursor: 'pointer',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(4),
  },
}))

const ModalInfo = ({
  children, open, onClose, close, ...props
}) => {
  const classes = useStyles()
  return (
    <Modal
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      open={open}
      {...props}
    >
      <Fade in={open}>
        <Box className={classes.modal}>
          <Paper className={classes.paper}>
            {close && (
              <Icon
                title="Fechar"
                className={classes.close}
                onClick={onClose}
              >
                close
              </Icon>
            )}
            {children}
          </Paper>
        </Box>
      </Fade>
    </Modal>
  )
}

ModalInfo.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
}

ModalInfo.defaultProps = {
  open: false,
  close: true,
  onClose: () => { },
}

export default ModalInfo
