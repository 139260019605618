import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import useSecurity from '../shared/hooks/useSecurity'
import { useSecurityAction } from '../store/ducks/security'
import useUserClient from '../clients/UserClient/useUserClient'

axios.timeout = 60000
axios.defaults.baseURL = process.env.REACT_APP_BFF_URL

const AxiosSetting = ({
  handleError,
  handleShowLoading,
  onStopRequest: stopRequest,
  onStartRequest: startRequest,
}) => {
  const {
    signout,
    getUser,
    getCredential,
  } = useSecurity()

  const [count, setCount] = useState(0)
  const [responseError, setResponseError] = useState()

  const userClient = useUserClient()

  const {
    setCredential,
  } = useSecurityAction()

  const catchResponse = (response) => {
    setResponseError(response)
    setCount((current) => current - 1)
    return Promise.reject(response)
  }

  useEffect(() => {
    let token
    const requestId = axios.interceptors.request.use((config) => {
      handleShowLoading(!config?.noLoading)
      setCount((current) => current + 1)

      const newConfig = { ...config }

      // if (currentUser && !config.url.substring(0, 6).includes('http')) {
      const { headers } = newConfig

      const { t } = getCredential()
      token = t
      if (t) {
        headers.Authorization = `Bearer ${t}`
      }
      // }

      return newConfig
    }, (error) => {
      setCount((current) => current - 1)
      return Promise.reject(error)
    })

    const responseId = axios.interceptors.response.use((response) => {
      setCount((current) => current - 1)
      if (response?.data) {
        if (response?.data?.StatusCode
          && response?.data?.StatusCode !== null
          && response?.data?.StatusCode !== 200
        ) {
          const data = new Error(response?.data?.Message)
          data.status = response?.data?.StatusCode
          return catchResponse({ data })
        }

        if (response?.data?.Data
          && response?.data?.Data !== null
        ) {
          response.data = response?.data?.Data
        }
      }

      return response
    }, (error) => {
      let { response = {} } = error

      if (response.status === 401) {
        if (token) {
          userClient().revalidateTokenUser().then((res) => {
            setCredential({ t: res.token })
          })
        } else {
          response = { ...response, data: { title: 'Sessão expirada!', message: 'Faça o login novamente!' } }
          signout()
        }
      }

      if (response.status === 403) {
        response = { ...response, data: '' }
        signout()
      }

      if (response.status === 404) {
        response = { ...response, data: { title: response.status, message: 'Não encontrado.' } }
      }

      if (response.status === 500) {
        response = {
          ...response,
          data: {
            title: `Erro Interno - ${response.status}`,
            message: 'Contate o administrador do sistema!',
          },
        }
      }

      return catchResponse(response)
    })

    return () => {
      axios.interceptors.request.eject(requestId)
      axios.interceptors.response.eject(responseId)
    }
  }, [getUser, signout, getCredential, handleShowLoading, userClient, setCredential])

  useEffect(() => {
    if (count === 1) {
      startRequest()
    }

    if (count === 0) {
      stopRequest()
    }
  }, [count, startRequest, stopRequest])

  useEffect(() => {
    if (responseError) {
      const handle = handleError[responseError.status]

      if (handle) {
        handle(responseError)
      }
    }
  }, [responseError, handleError])
}

AxiosSetting.propTypes = {
  handleError: PropTypes.object,
  handleShowLoading: PropTypes.func.isRequired,
  onStartRequest: PropTypes.func.isRequired,
  onStopRequest: PropTypes.func.isRequired,
}

AxiosSetting.defaultProps = {
  handleError: {},
}

export default AxiosSetting
