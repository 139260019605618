/* eslint-disable array-callback-return */
import React, {
  useMemo, useState, useLayoutEffect, useEffect, useCallback,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import makeStyles from '@mui/styles/makeStyles'
import CloseIcon from '@mui/icons-material/Close'

import Nav from './Nav'
import Main from './Main'
import Footer from './footer/Footer'
import Header from './header/Header'
import SubFooter from './footer/SubFooter'
import useSecurity from '../hooks/useSecurity'
import useUtils from '../hooks/useUtils'
import TemplateContext from '../contexts/TemplateContext'
import { useSecurityAction } from '../../store/ducks/security'
import useUserClient from '../../clients/UserClient/useUserClient'
import { TYPES_ALERTS, COLLECTION_ALERTS } from '../../pages/alerts/constants'
import { ROLES_REGIONALS } from '../constants'

const useStyles = makeStyles((theme) => ({
  navbar: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
    },
  },
  container: {
    backgroundColor: '#F6F6F6',
    height: '100%',
  },
  containerAlerts: {
    position: 'relative',
  },
  subContainer: {
    width: '100%',
    zIndex: 2,
  },
  content: {
    width: '100%',
    display: 'flex',
  },
  footer: {
    width: '100%',
    height: theme.sizes.header,
    '& footer': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  alerts: {
    color: '#F6F6F6',
    backgroundColor: theme.palette.text.quaternary,
  },
  alertTitle: {
    color: '#F6F6F6',
    paddingLeft: 155,
    paddingRight: 155,
    backgroundColor: theme.palette.text.quaternary,
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  alertItem: {
    color: '#F6F6F6',
    backgroundColor: theme.palette.text.quaternary,
    borderRadius: 0,
    display: 'flex',
    padding: '8px 155px 0px 155px',
    alignItems: 'center',
    '& .MuiAlert-message': {
      padding: 0,
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  seeButton: {
    fontSize: 14,
    cursor: 'pointer',
    fontWeight: 'bold',
    paddingLeft: theme.spacing(4),
    color: theme.palette.text.contrastText,
  },
}))

const alertsEmpty = { countAlerts: 0, listAlerts: [] }

const Master = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const userClient = useUserClient()

  const error = useMemo(() => {
    const { pathname } = location
    return pathname.search('error') === 1
  }, [location])

  const isRequest = useMemo(() => {
    const { pathname } = location
    return pathname === '/solicitacoes'
  }, [location])

  const classes = useStyles({ error })

  const { bgImage, crumbs, children } = props
  const { Provider: TemplateProvider } = TemplateContext

  const { signout, getUserRoles } = useSecurity()

  const { user } = useSelector(({ security }) => ({ user: security.user }))
  const { listAlerts = [] } = user?.alerts ?? {}

  const { setUser } = useSecurityAction()
  const { getTypesNotification } = useUtils()

  const [showAlerts, setShowAlerts] = useState(false)
  const [mobileOpen, setMobileOpen] = useState(false)
  const [titlePage, setTitlePage] = useState(crumbs[crumbs.length - 1]?.name)

  const typesNotifications = getTypesNotification()

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen)

  const sair = () => signout()

  const userRoles = getUserRoles()
  const regionalAll = useMemo(() => userRoles
    ?.map(({ id }) => ROLES_REGIONALS?.find((item) => item?.id === id)), [userRoles])

  const regionals = useMemo(() => {
    const array = []
    regionalAll?.map((item) => {
      if (item?.nameAdjusted) {
        array.push(item?.nameAdjusted)
      }
      return ''
    })
    return array
  }, [regionalAll])

  const handleToAlerts = (alert) => {
    setShowAlerts(false)
    navigate('/alertas', {
      state: {
        fromMenu: true,
        object: {
          type: alert?.notificationType ?? '',
        },
      },
    })
  }

  const handleCloseAlerts = () => setShowAlerts(false)

  const getAlertsUnread = useCallback(() => new Promise(
    (resolve, reject) => {
      userClient().getAlertsUnread({
        UserEmail: user?.email, Regions: regionals,
      }).then((response) => {
        resolve(response)
      }, (err) => {
        reject(err)
      })
    },
  ), [userClient, user, regionals])

  useEffect(() => {
    getAlertsUnread().then(
      (response) => {
        const { data } = response || {}
        const listAlertsResponse = data?.data ?? []
        const countAlerts = data?.totalRows ?? 0
        const types = COLLECTION_ALERTS.map((type) => type.value)
        const alerts = []

        listAlertsResponse.map((alert) => {
          if (types.includes(alert.notificationType)) {
            alerts.push(alert)
          }
        })

        if (countAlerts > 0) {
          setUser({
            ...user,
            alerts: {
              countAlerts,
              listAlerts: alerts,
            },
          })

          setShowAlerts(true)
        } else {
          setUser({ ...user, alerts: alertsEmpty })
          setShowAlerts(false)
        }
      },
      () => {
        setUser({ ...user, alerts: alertsEmpty })
        setShowAlerts(false)
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    const updateWindowDimensions = () => {
      if (window.innerWidth >= 960) {
        setMobileOpen(false)
      }
    }

    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  return (
    <TemplateProvider value={{ titlePage, mobileOpen, setTitlePage }}>
      <Grid item className={classes.navbar}>
        <Nav logout={sair} onDrawerToggle={handleDrawerToggle} />

        <Grid container className={classes.containerAlerts}>
          {(showAlerts && isRequest && listAlerts?.length > 0) && (
            <>
              <Grid item xs={12}>
                <Box className={classes.overdueTickets}>
                  <Alert icon={false} className={classes.alertItem}>
                    <Box display="flex" justifyContent="space-between" width={1}>
                      <Typography variant="h6" color="secondary">
                        <b>Alertas</b>
                      </Typography>

                      <Button onClick={handleCloseAlerts}>
                        <CloseIcon color="secondary" />
                      </Button>
                    </Box>
                  </Alert>
                </Box>
              </Grid>

              {listAlerts.map((alert, index) => (
                <React.Fragment key={`alert-${index}`}>
                  {typesNotifications.includes(alert.notificationType) && (
                    <Grid item xs={12}>
                      <Box className={classes.overdueTickets}>
                        <Alert
                          severity="error"
                          icon={TYPES_ALERTS[alert.notificationType].icon()}
                          className={classes.alertItem}
                        >
                          <Box display="flex" alignItems="center">
                            <Typography variant="subtitle2">
                              {TYPES_ALERTS[alert.notificationType].description}
                            </Typography>

                            <Button
                              color="inherit"
                              onClick={() => handleToAlerts(alert)}
                              className={classes.seeButton}
                            >
                              <Typography variant="subtitle2">
                                {TYPES_ALERTS[alert.notificationType].viewText}
                                {' '}
                                &gt;
                              </Typography>
                            </Button>
                          </Box>
                        </Alert>
                      </Box>
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </Grid>

        <Header
          crumbs={crumbs}
          isError={error}
          bgImage={bgImage}
          handleDrawerToggle={handleDrawerToggle}
        />

      </Grid>

      <Grid item className={classes.subContainer}>
        <Grid container className={classes.container}>
          <Main className={classes.content}>
            {children}
          </Main>
        </Grid>

        <footer hidden={error}>
          <SubFooter />
          <Footer />
        </footer>
      </Grid>
    </TemplateProvider>
  )
}

Master.propTypes = {
  crumbs: PropTypes.array,
  bgImage: PropTypes.any,
  children: PropTypes.element.isRequired,
}

Master.defaultProps = {
  crumbs: [],
  bgImage: undefined,
}

export default Master
