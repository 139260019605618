import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import Dialog from '@mui/material/Dialog'
import Backdrop from '@mui/material/Backdrop'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'

import makeStyles from '@mui/styles/makeStyles'

import ButtonGradient from './Button/ButtonGradient'

import icoError from '../../assets/img/icons/ico-alert-danger.svg'
import icoAlert from '../../assets/img/icons/ico-alert-warning.svg'
import icoSuccess from '../../assets/img/icons/ico-alert-success.svg'

const useStyles = makeStyles((theme) => ({
  btn: {
    width: '100%',
  },
  close: {
    cursor: 'pointer',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '16px',
    },
  },
  modal: {
    paddingBottom: theme.spacing(4),
    color: theme.palette.text.tertiary,
    overflow: 'auto',
    '& img': {
      width: 174,
    },
  },
  bottom: {
    margin: '0 !important',
  },

}))

const MessageBox = (props) => {
  const classes = useStyles()
  const {
    opened, type, title, text, thumb, labelPrimary, labelSecondary,
    handleClose, handlePrimary, handleSecondary, children, buttonPosition,
    maxWidth,
  } = props

  return (
    <Dialog
      open={opened}
      maxWidth={maxWidth}
      onClose={handleClose}
      className={classes.dialog}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEscapeKeyDown
      fullWidth
    >
      <DialogContent className={classes.modal}>
        <Icon
          title="Fechar"
          className={classes.close}
          onClick={handleClose}
        >
          close
        </Icon>

        {(thumb || type) && (
          <Box mt={2} mb={3} display="flex" justifyContent="center">
            {type === 'error' && (<img src={icoError} alt="thumb" />)}
            {type === 'warning' && (<img src={icoAlert} alt="thumb" />)}
            {type === 'success' && (<img src={icoSuccess} alt="thumb" />)}
            {type === 'info' && thumb && (<img src={thumb} alt="thumb" />)}
          </Box>
        )}

        {title && (
          <Box mt={2} mb={3}>
            <Typography align="center" variant="h5">{title}</Typography>
          </Box>
        )}

        {text && (
          <Box mt={2} mb={3} align="center">
            {text.length < 113 && (
              <Typography align="center" dangerouslySetInnerHTML={{ __html: text }} />
            )}

            {text.length >= 113 && (
              <Typography align="justify" dangerouslySetInnerHTML={{ __html: text }} />
            )}
          </Box>
        )}

        {children}

        {buttonPosition === 'nextTo' && (
          <Grid pb={2} container justifyContent="center" className={classes.bottom}>
            {handleSecondary && (
              <Grid item lg={5} sm={6} xs={12}>
                <ButtonGradient
                  color="primary"
                  variant="outlined"
                  onClick={handleSecondary}
                  title={labelSecondary}
                >
                  {labelSecondary}
                </ButtonGradient>
              </Grid>
            )}

            {handlePrimary && (
              <Grid item lg={5} sm={6} xs={12}>
                <ButtonGradient
                  color="primary"
                  variant="contained"
                  title={labelPrimary}
                  onClick={handlePrimary}
                >
                  {labelPrimary}
                </ButtonGradient>
              </Grid>
            )}
          </Grid>
        )}

        {buttonPosition === 'below' && (
          <Grid pb={3} container justifyContent="center" className={classes.bottom}>
            {handlePrimary && (
              <Grid item lg={7} sm={8} xs={12}>
                <ButtonGradient
                  color="primary"
                  variant="contained"
                  title={labelPrimary}
                  onClick={handlePrimary}
                >
                  {labelPrimary}
                </ButtonGradient>
              </Grid>
            )}

            {handleSecondary && (
              <Grid item lg={7} sm={8} xs={12}>
                <ButtonGradient
                  color="primary"
                  variant="outlined"
                  title={labelSecondary}
                  onClick={handleSecondary}
                >
                  {labelSecondary}
                </ButtonGradient>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

MessageBox.propTypes = {
  opened: PropTypes.bool,
  children: PropTypes.element,
  buttonPosition: PropTypes.oneOf(['nextTo', 'below']),
  maxWidth: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']),
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
  handlePrimary: PropTypes.func,
  handleSecondary: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  thumb: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  labelPrimary: PropTypes.string,
  labelSecondary: PropTypes.string,
}

MessageBox.defaultProps = {
  type: 'info',
  maxWidth: 'sm',
  buttonPosition: 'nextTo',
  opened: false,
  text: undefined,
  thumb: undefined,
  title: undefined,
  labelPrimary: 'Confirmar',
  labelSecondary: 'Cancelar',
  handlePrimary: undefined,
  handleSecondary: undefined,
  children: undefined,
}

export default MessageBox
