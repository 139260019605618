import React, {
  useRef,
  useEffect,
  useContext,
  useCallback,
} from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../shared/contexts/AuthContext'

import { useSecurityAction } from '../../store/ducks/security'

import { ROLES_REGIONALS } from '../../shared/constants'
import SlashScreen from '../../shared/components/SlashScreen'

import useUserClient from '../../clients/UserClient/useUserClient'

const AccessCallback = () => {
  const navigate = useNavigate()

  const userClient = useUserClient()
  const userClientName = useUserClient()

  const {
    user,
  } = useContext(AuthContext)

  const {
    setUser,
    setUserRoles,
    setCredential,
  } = useSecurityAction()

  const getIpAddress = async () => {
    const response = await fetch('https://api.ipify.org/?format=json')
    const data = await response.json()
    localStorage.setItem('ipAddress', data?.ip)
    userClient().sendIPAddress(user?.email, data?.ip, 'EXECUTIVO')
  }

  useEffect(() => {
    getIpAddress()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loginUserRequest = useCallback(() => {
    setCredential({ t: user.credential })
    if (user.id) {
      userClient().getUserRoles(user.login, user.credential).then((response) => {
        setUserRoles(response)

        const regionalAll = response
          ?.map(({ id }) => ROLES_REGIONALS?.find((item) => item?.id === id))

        const regionals = () => {
          const array = []
          regionalAll?.map((item) => {
            if (item?.nameAdjusted) {
              array.push(item?.nameAdjusted)
            }
            return ''
          })
          return array
        }
        const payloadUserName = {
          userEmail: user?.email,
          regions: regionals(),
        }

        userClientName().loginUserName(payloadUserName)
          .then((responseNameExecutive) => {
            const nameExecutiveLogger = responseNameExecutive?.data?.data?.name
            setUser({ ...user, nameExecutive: nameExecutiveLogger ?? null })
          }, () => {
            setUser({ ...user, nameExecutive: null })
          }).finally(() => {
            navigate('/solicitacoes', { replace: true })
          })
      })
    } else {
      navigate('/login', { replace: true })
    }
  }, [
    user,
    userClient,
    userClientName,
    setUser,
    navigate,
    setUserRoles,
    setCredential,
  ])

  const memoryLeak = useRef(false)

  useEffect(() => {
    if (user && !memoryLeak.current) {
      loginUserRequest()
    }
    return () => {
      memoryLeak.current = true
    }
  }, [
    user,
    navigate,
    loginUserRequest,
  ])

  return (<SlashScreen />)
}

export default AccessCallback
