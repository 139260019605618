import { useCallback } from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { isCnpj } from 'validator-brazil'

import { COLLECTION_ALERTS } from '../../pages/alerts/constants'

const useUtils = () => {
  const getOnlyNumber = useCallback((value = '') => value.replace(/\D/g, ''), [])

  const checkStringEmpty = useCallback((value) => (
    value ?? ''
  ), [])

  const ternaryCheck = useCallback((condition, firstParam, secondParam = null) => (
    condition ? firstParam : secondParam
  ), [])

  const isBlank = useCallback((value) => {
    let data = ''

    if (typeof value === 'boolean') {
      data = `${value}`
    } else {
      data = `${value || ''}`
    }

    return isEmpty(data) || data.trim().length === 0
  }, [])

  const formatCurrency = useCallback((value) => {
    let formatValue

    const currency = new Intl.NumberFormat([], {
      style: 'currency',
      currency: 'BRL',
    })

    if (value !== '' || !isBlank(value)) {
      formatValue = currency.format(value)
    }

    return formatValue
  }, [isBlank])

  const formatCurrencyBRLToDecimal = useCallback((value) => {
    let formatValue = 0

    if (!isBlank(value)) {
      formatValue = parseFloat(String(value).slice(3).replaceAll('.', '').replaceAll(',', '.'))
    }

    return formatValue
  }, [isBlank])

  const formatCEP = useCallback((value) => !isBlank(value) && value.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2-$3'), [isBlank])

  const formatCPF = useCallback((value) => !isBlank(value) && value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'), [isBlank])

  const formatCNPJ = useCallback((value) => !isBlank(value) && value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'), [isBlank])

  const formatCPFOrCNPJ = useCallback((value) => {
    if (value) {
      return isCnpj(value) ? formatCNPJ(value) : formatCPF(value)
    }
    return null
  }, [formatCPF, formatCNPJ])

  const formatSUSEP = useCallback((value) => !isBlank(value) && value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4'), [isBlank])

  const formatPhoneOrCellphone = useCallback((value) => {
    if (!isBlank(value)) {
      let number = getOnlyNumber(value)
      number = number.replace(/^(\d{2})(\d)/g, '($1) $2')
      number = number.replace(/(\d)(\d{4})$/, '$1-$2')
      return number
    }
    return ''
  }, [isBlank, getOnlyNumber])

  const date = useCallback((value) => (value ? moment(value) : moment()), [])

  const dateHoursZero = useCallback((value) => {
    let dateHours = value ?? moment()
    if (typeof value !== 'object') {
      dateHours = moment(value)
    }

    dateHours.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })

    return dateHours.utc()
  }, [])

  const formatHours = useCallback((value) => value && moment(value).utc().format('LT'), [])

  const formatDate = useCallback((value) => value && moment(value).utc().format('DD/MM/YYYY'), [])

  const formatDateApi = useCallback((value) => value && moment(value).utc().format('YYYY-MM-DD'), [])

  const formatDateNews = useCallback((value) => value && `${moment(value).utc().format('DD')} de ${moment(value).utc().format('MMMM YYYY')}`, [])

  const makeQueryPromise = (promise) => {
    if (promise.isFulfilled) {
      return promise
    }

    let isPending = true
    let isRejected = false
    let isFulfilled = false

    const result = promise.then((v) => {
      isFulfilled = true
      isPending = false
      return v
    }, (e) => {
      isRejected = true
      isPending = false
      throw e
    })

    result.isPending = () => isPending
    result.isRejected = () => isRejected
    result.isFulfilled = () => isFulfilled
    return result
  }

  const getTypesNotification = useCallback(() => {
    const listType = []
    COLLECTION_ALERTS.map((i) => {
      if (i.value !== 'All') {
        listType.push(i.value)
      }
      return ''
    })

    return listType
  }, [])

  return {
    date,
    dateHoursZero,
    isBlank,
    formatCPF,
    formatCEP,
    formatCNPJ,
    formatDate,
    formatHours,
    formatSUSEP,
    ternaryCheck,
    getOnlyNumber,
    formatDateApi,
    formatCurrency,
    formatDateNews,
    formatCPFOrCNPJ,
    checkStringEmpty,
    formatPhoneOrCellphone,
    formatCurrencyBRLToDecimal,
    makeQueryPromise,
    getTypesNotification,
  }
}

export default useUtils
