import { useCallback } from 'react'
import axios from 'axios'

const useDebtsClient = () => {
  const getFinancial = useCallback(
    (filters) => {
      const params = new URLSearchParams({
        Skip: filters?.Skip ?? 0,
        Take: 20,
        OrderBy: filters?.OrderBy ?? 'DueStartDesc',
        reloadCache: false,
      })
      filters?.Regions?.map((item) => params.append('Regions', `${item}`))
      filters?.ExecutiveName?.map((item) => params.append('ExecutiveName', `${item}`))
      filters?.ExecutiveEmail?.map((item) => params.append('ExecutiveEmail', `${item}`))
      params.append('UserEmail', `${filters?.UserEmail}`)
      params.append('Search', `${filters?.Search}`)
      params.append('InsuredDocument', `${filters?.InsuredDocument}`)
      params.append('ClientDocument', `${filters?.ClientDocument}`)
      params.append('InsuredName', `${filters?.InsuredName}`)
      params.append('ClientName', `${filters?.ClientName}`)
      params.append('DueStart', `${filters?.DueStart}`)
      params.append('DueEnd', `${filters?.DueEnd}`)
      params.append('Office', `${filters?.Office}`)
      params.append('Status', `${filters?.Status}`)

      return axios.get(`/financial?${params}`)
    },
    [],
  )

  const getListOfficesByRegional = useCallback(async (data) => axios.post('office/by-regional', data, {
    noLoading: true,
  }).then((response) => (response?.data ? response : { data: { ...response } })), [])

  const getListExecutivesByRegional = useCallback(async (role) => axios.post('/executives/by-regional', {
    role,
  }), [])

  const exportInstancesExcel = useCallback(
    (filters) => {
      const params = new URLSearchParams({
        Skip: filters?.Skip ?? 0,
        Take: 20,
        OrderBy: filters?.OrderBy ?? 'DueStartDesc',
        reloadCache: false,
      })
      filters?.Regions?.map((item) => params.append('Regions', `${item}`))
      filters?.ExecutiveName?.map((item) => params.append('ExecutiveName', `${item}`))
      filters?.ExecutiveEmail?.map((item) => params.append('ExecutiveEmail', `${item}`))
      params.append('UserEmail', `${filters?.UserEmail}`)
      params.append('Search', `${filters?.Search}`)
      params.append('InsuredDocument', `${filters?.InsuredDocument}`)
      params.append('ClientDocument', `${filters?.ClientDocument}`)
      params.append('InsuredName', `${filters?.InsuredName}`)
      params.append('ClientName', `${filters?.ClientName}`)
      params.append('DueStart', `${filters?.DueStart}`)
      params.append('DueEnd', `${filters?.DueEnd}`)
      params.append('Office', `${filters?.Office}`)
      params.append('Status', `${filters?.Status}`)

      return axios.get(`/financial/export/excel?${params}`, { responseType: 'arraybuffer' })
    },
    [],
  )

  const getInvoiceDocument = useCallback((data) => axios.get('financial/invoices/document', {
    params: { ...data },
  }).then((response) => (response?.data ? response : { data: { ...response } })), [])

  const getPolicyDocument = useCallback(
    ({ policyNumber, regionals = [] }) => {
      const params = new URLSearchParams({
        code: policyNumber,
      })

      regionals.map((region) => {
        params.append('regions', `${region}`)
        return ''
      })

      return axios.get(`insurancepolicy/document/byregional?${params}`)
    },
    [],
  )

  const getListOwnerEmails = useCallback(
    (filters) => {
      const params = new URLSearchParams()

      filters?.regions?.map((item) => params.append('regions', `${item}`))

      return axios.get(`salesforce/executive?${params}`)
    },
    [],
  )

  const getNamesClients = useCallback((data) => axios.post('/salesforce/clientsByRegional', data), [])

  return useCallback(() => ({
    getFinancial,
    getNamesClients,
    getInvoiceDocument,
    exportInstancesExcel,
    getListOfficesByRegional,
    getListExecutivesByRegional,
    getPolicyDocument,
    getListOwnerEmails,
  }), [
    getFinancial,
    getNamesClients,
    getInvoiceDocument,
    exportInstancesExcel,
    getListOfficesByRegional,
    getListExecutivesByRegional,
    getPolicyDocument,
    getListOwnerEmails,
  ])
}

export default useDebtsClient
