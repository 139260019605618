import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react'

import { useNavigate, useLocation } from 'react-router-dom'

import { useAlert } from '../../../../shared/components/Alert'
import { useTemplateContext } from '../../../../shared/contexts/TemplateContext'
import RegistrationFormContext from '../../../../shared/contexts/RegistrationFormContext'

import usePolicyRegistrationClient from '../../../../clients/PolicyRegistrationClient/usePolicyRegistrationClient'

import ConfirmationPolicy from './Confirmation'
import RegistrationData from '../../../../models/PolicyModels/RegistrationData'

const StepType = {
  QUOTE_CONFIRMATION: 0,
}

const Details = () => {
  const { Provider } = RegistrationFormContext

  const navigate = useNavigate()
  const policyRegistrationClient = usePolicyRegistrationClient()

  const { state } = useLocation()
  const { setTitlePage } = useTemplateContext()
  const { cleanMsg, addMsgDanger } = useAlert()
  const { addMsgSuccess, addMsgDanger: addMsgError } = useAlert()

  const [steps] = useState([
    StepType.QUOTE_CONFIRMATION,
  ])
  const [stepsRef, setStepsRef] = useState({})
  const [actionNext, setActionNext] = useState(false)
  const [confirmation, setConfirmation] = useState({})
  const [policyDetails, setPolicyDetails] = useState()
  const [actionPrevious, setActionPrevious] = useState(false)
  const [activeStep, setActiveStep] = useState(StepType.QUOTE_CONFIRMATION)

  const PolicyConfirmationRef = useRef()

  useEffect(() => {
    const stepsControl = {}
    stepsControl[StepType.QUOTE_CONFIRMATION] = PolicyConfirmationRef

    setStepsRef(stepsControl)
  }, [
    setStepsRef,
    PolicyConfirmationRef,
  ])

  // const isActiveStep = (step) => activeStep === step

  const setDataPolicyDetails = useCallback((policy) => {
    const policyTemplate = policy[0].template
    setPolicyDetails(policyTemplate)
  }, [
    setPolicyDetails,
  ])

  const handleNext = () => {
    const stepRef = stepsRef[activeStep]
    stepRef.current.onSubmit().then((data) => {
      if (data) {
        addMsgDanger(data)
      } else {
        setActionNext(true)
        setActionPrevious(false)
      }
    })
  }

  const onNext = () => {
    cleanMsg()
    if (steps.includes(activeStep + 1)) {
      setActiveStep(activeStep + 1)
    }
  }

  const messageSuccess = () => {
    if (state?.policy?.data?.policyData?.typeDocument === 'Endosso') {
      return state?.policy?.data?.policyData?.codigo !== '' ? 'Endosso atualizado com sucesso' : 'Endosso cadastrado com sucesso'
    }
    return state?.policy?.data?.policyData?.codigo !== '' ? 'Apólice atualizado com sucesso' : 'Apólice cadastrada com sucesso'
  }

  const returnToPreviousScreen = () => {
    if (state?.policy?.data?.policyData?.codigo !== '') {
      navigate('/apolices', {
        state: {
          filterSelectedFields: state?.filterSelectedFields,
          filterSelectedPayload: state?.filterSelectedPayload,
        },
      })
    } else {
      navigate('/cadastro-de-apolices', { replace: true })
    }
  }

  const onRegister = (data) => {
    const dataSuccess = {
      title: data?.Title,
      message: messageSuccess(),
      labelButton: 'Voltar',
      action: () => returnToPreviousScreen(),
    }

    addMsgSuccess(dataSuccess)
  }

  const handleEdit = () => {
    const policy = policyDetails
    if (policy) {
      navigate('/cadastro-de-apolices/cadastro', {
        state: {
          filterSelectedFields: state?.filterSelectedFields,
          filterSelectedPayload: state?.filterSelectedPayload,
          edit: true,
          policy: { ...policy },
        },
      })
    }
  }

  const onError = (error) => {
    if (error?.data?.message) {
      addMsgError(error?.data?.message)
    }

    const dataError = {
      title: '',
      message: error?.data?.message,
      labelButton: 'Voltar',
      action: () => handleEdit(),
    }

    addMsgError(dataError)
  }

  const registrationData = RegistrationData(state?.policy?.data)

  const insuranceCompany = registrationData?.seguradora?.id !== '' ? {
    name: registrationData?.seguradora?.name,
    id: registrationData?.seguradora?.id,
  } : {
    name: registrationData?.seguradora?.name,
  }

  const renderPolicy = () => (
    <ConfirmationPolicy
      filterSelectedFields={state.filterSelectedFields}
      filterSelectedPayload={state.filterSelectedPayload}
      policy={policyDetails}
      ref={stepsRef[StepType.QUOTE_CONFIRMATION]}
      handleNext={() => {
        const isEndorsement = registrationData.tipoDocumento === 'Endosso'
        const payload = {
          ...registrationData,
          seguradora: insuranceCompany,
          tipoEndosso: {
            descricaoEndosso: isEndorsement ? registrationData
              ?.tipoEndosso?.descricaoEndosso : null,
            numeroTipoEndosso: isEndorsement ? registrationData
              ?.tipoEndosso?.numeroTipoEndosso : null,
          },
          numeroPrincipal: isEndorsement ? registrationData
            ?.numeroPrincipal : null,
          numeroEndossada: isEndorsement ? registrationData
            ?.numeroEndossada : null,
        }
        if (payload?.codigo !== '') {
          policyRegistrationClient()
            .editPolicyRegistration(payload).then((response) => {
              onRegister(response?.data)
            }, onError)
        } else {
          policyRegistrationClient()
            .sendPolicyRegistration(payload).then((response) => {
              onRegister(response?.data)
            }, onError)
        }
        handleNext()
      }}
      handlePrevious={() => handleEdit()}
    />
  )

  useEffect(() => {
    if (state?.policy) {
      setTitlePage('Confirmação')
      const policyTemplate = policyRegistrationClient()
        .getPolicyTemplate(state?.policy?.data)

      setDataPolicyDetails(policyTemplate)
    }
  }, [
    setTitlePage,
    state?.policy,
    policyRegistrationClient,
    setDataPolicyDetails,
    state,
  ])

  return (
    <Provider value={{
      confirmation,
      setConfirmation,
      actionNext,
      actionPrevious,
      onNext,
      onRegister,
      addMsgError,
    }}
    >
      {renderPolicy()}
    </Provider>
  )
}

export default Details
