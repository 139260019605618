import React, {
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'

import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import makeStyles from '@mui/styles/makeStyles'

import { useLoader } from '../../../../shared/components/Loader'
import ButtonLight from '../../../../shared/components/Button/ButtonLight'
import ButtonGradient from '../../../../shared/components/Button/ButtonGradient'
import { useRegistrationForm } from '../../../../shared/contexts/RegistrationFormContext'
import { VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR } from '../../../../shared/messages/Global'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiCollapse-root': {
      borderRadius: 14,
    },
  },
  header: {
    height: 120,
    padding: 40,
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: theme.spacing(2, 5, 4),
  },
  labelInfo: {
    color: theme.palette.text.tertiary,
  },
  buttonsStep: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    '& .MuiBox-root': {
      minWidth: 190,
      [theme.breakpoints.down('xs')]: {
        marginTop: 5,
        marginLeft: 5,
        minWidth: 115,
      },
    },
  },
}))

const DEFAULT_CONFIRMATION = {
  responsibilityConfirmation: false,
}

const DataConfirmation = forwardRef((props, ref) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const {
    children,
    handleNext,
    handlePrevious,
    filterSelectedFields,
    filterSelectedPayload,
  } = props

  const { showLoader, hideLoader } = useLoader()

  const {
    onNext,
    actionNext,
  } = useRegistrationForm()

  const [buttonDisabled, setButtonDisabled] = useState(false)

  const formik = useFormik({
    initialValues: { ...DEFAULT_CONFIRMATION },

    onSubmit: (data, { setSubmitting }) => {
      setSubmitting(false)

      if (actionNext) {
        showLoader()

        setTimeout(() => {
          hideLoader()
        }, 800)

        onNext({})
      }
    },
  })

  useImperativeHandle(ref, () => ({
    onSubmit: () => new Promise((resolve) => {
      formik.validateForm().then((errors) => {
        let data

        if (!isEmpty(errors)) {
          data = { message: VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR }
        }

        resolve(data)
        formik.submitForm()
      })
    }),
  }))

  const handleContinue = () => {
    setButtonDisabled(true)
    handleNext()
  }

  const backToListOfPolicies = () => {
    navigate('/apolices', {
      state: {
        filterSelectedFields,
        filterSelectedPayload,
      },
    })
  }

  return (
    <Box className={classes.root}>
      <Paper elevation={1}>
        <Grid container>
          <Grid
            item
            xs={12}
            className={classes.header}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography gutterBottom variant="h5" color="initial">
                  Confirme os dados da apólice
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.labelInfo}>
                  Confira abaixo as informações da apólice
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.content}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                {children}
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.buttonsStep}>
                  <Box>
                    <ButtonLight onClick={handlePrevious} title="Editar">
                      Editar
                    </ButtonLight>
                  </Box>
                  {filterSelectedPayload?.UserEmail && (
                    <Box>
                      <ButtonLight onClick={backToListOfPolicies} title="Voltar">
                        Voltar
                      </ButtonLight>
                    </Box>
                  )}
                  <Box display="flex" gridGap="10px" alignItems="center">
                    <ButtonGradient onClick={handleContinue} disabled={buttonDisabled}>
                      Salvar
                    </ButtonGradient>
                  </Box>
                  {buttonDisabled && (
                    <Box>
                      <CircularProgress style={{ width: 30, height: 30 }} />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
})

DataConfirmation.propTypes = {
  children: PropTypes.any,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  filterSelectedFields: PropTypes.object,
  filterSelectedPayload: PropTypes.object,
}

DataConfirmation.defaultProps = {
  children: '',
  filterSelectedFields: {},
  filterSelectedPayload: {},
}

export default DataConfirmation
