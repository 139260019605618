import { useMemo } from 'react'

import { DEFAULT_THEME } from '../constants'

const useSettings = () => {
  const color = useMemo(() => {
    const current = DEFAULT_THEME

    return {
      ...current,
      primary: current.primary || DEFAULT_THEME.primary,
    }
  }, [])

  return {
    color,
  }
}

export default useSettings
