import { useCallback } from 'react'
import axios from 'axios'

const usePolicyClient = () => {
  const getPolicies = useCallback((data, noLoading = false) => axios.post('insurancepolicy/search', data, { noLoading }), [])

  const getDocumentPolicy = useCallback((policyNumber) => axios.get(`insurancepolicy/document?codigo=${policyNumber}&base64=true`), [])

  const getAllTickets = useCallback((policyNumber) => axios.get(`insurancepolicy/invoices/download/all?documentNumber=${policyNumber}&base64=true`), [])

  const downloadSummaryPolicy = useCallback(async (data) => axios.post('insurancepolicy/export/excel', data, {
    responseType: 'arraybuffer',
  }).then((response) => (response?.data ? response : { data: { ...response } })), [])

  const sendPolicyKit = useCallback((policyNumber, email) => axios.get(`InsurancePolicy/SendPolicyKit?policynumber=${policyNumber}&${email}`), [])

  return useCallback(() => ({
    getPolicies,
    sendPolicyKit,
    getAllTickets,
    getDocumentPolicy,
    downloadSummaryPolicy,
  }), [
    getPolicies,
    sendPolicyKit,
    getAllTickets,
    getDocumentPolicy,
    downloadSummaryPolicy,
  ])
}

export default usePolicyClient
