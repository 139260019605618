import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import DataConfirmation from './DataConfirmation'
import ContainerFields from './ContainerFields'

const Confirmation = forwardRef((props, ref) => {
  const { policy } = props

  return (
    <DataConfirmation
      ref={ref}
      {...props}
    >
      <ContainerFields policy={policy} />
    </DataConfirmation>
  )
})

Confirmation.propTypes = {
  policy: PropTypes.any,
}

Confirmation.defaultProps = {
  policy: undefined,
}

export default Confirmation
