import React, { useContext } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useNavigate, Link } from 'react-router-dom'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Input from '@mui/material/Input'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import FormHelperText from '@mui/material/FormHelperText'

import {
  GoEye,
  GoEyeClosed,
} from 'react-icons/go'

import useSecurity from '../../shared/hooks/useSecurity'
import {
  BLOCKED_USER,
  ERROR_PASSWORD_ENGLISH,
  ERROR_NO_USER_RECORD_ENGLISH,
} from '../../shared/constants'

import {
  ERRO_SENHA,
  BLOCKED_USER_MESSAGE,
  ERRO_USUARIO_INEXISTENTE,
} from '../../shared/messages/Global'

import logo from '../../assets/img/logo.svg'

import { useAlert } from '../../shared/components/Alert'
import { useLoader } from '../../shared/components/Loader'
import ButtonGradient from '../../shared/components/Button/ButtonGradient'
import { AuthContext } from '../../security/Auth'
import useStyles from './style'

const CheckLogin = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const { signin } = useSecurity()
  const { addMsgDanger } = useAlert()
  const { showLoader, hideLoader } = useLoader()
  const {
    setUserAuth,
    setUserLogin,
  } = useContext(AuthContext)

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  })

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const DEFAULT_VALUES_LOGIN = {
    login: '',
    password: '',
    issuer: 'W4B',
    application: process.env.REACT_APP_WINGS_APPLICATION,
    isHumanValidated: true,
  }

  const formik = useFormik({
    initialValues: { ...DEFAULT_VALUES_LOGIN },
    validationSchema: Yup.object({
      login: Yup.string().required(),
      password: Yup.string().required(),
      // .min(8, 'Senha curta - Deve ter no mínimo 8 caracteres.')
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (data) => {
      try {
        showLoader()
        await signin({ ...data }).then((response) => {
          const { user, token } = response.data
          hideLoader()

          if (user.mustChangePassword) {
            setUserLogin(user?.login)
            navigate('/change-password', { replace: true })
            return
          }

          user.credential = token

          setUserAuth(user)
          navigate('/callback', { replace: true })
        })
      } catch (res) {
        const response = res.data.Extensions.ValidationResults[0].ErrorCode

        hideLoader()
        if (response === BLOCKED_USER) {
          addMsgDanger({
            title: 'Seu usuário está bloqueado.',
            message: BLOCKED_USER_MESSAGE,
          })
        }
        if (response === ERROR_PASSWORD_ENGLISH) {
          addMsgDanger(ERRO_SENHA)
        }
        if (response === ERROR_NO_USER_RECORD_ENGLISH) {
          addMsgDanger(ERRO_USUARIO_INEXISTENTE)
        } else {
          const error = e
          addMsgDanger(error.message)
        }
      }
    },
  })

  return (
    <>
      <Grid item className={classes.header}>
        <Box className={classes.logo}>
          <img src={logo} alt="Finlandia" aria-label="Finlandia" />
        </Box>
      </Grid>
      <Box className={classes.titleContainer}>
        <Typography variant="h3" className={classes.title}>Área do Executivo</Typography>
      </Box>
      <p className="text">Digite os dados a seguir para acessar o portal</p>

      <form onSubmit={(e) => { e.preventDefault(); formik.submitForm() }} noValidate autoComplete="off" className={classes.form}>
        <Grid container spacing={4} className={classes.containerForm}>
          <Grid item sm={12} xs={12}>
            <InputLabel htmlFor="standard-adornment-login">Login</InputLabel>
            <Input
              fullWidth
              inputlabelprops={{
                shrink: true,
              }}
              title="Login"
              id="standard-adornment-login"
              type="text"
              placeholder="usuário / exemplo@email.com"
              {...formik.getFieldProps('login')}
              error={formik.touched.login && !!formik.errors.login}
              helpertext={formik.touched.login && formik.errors.login}
            />
            <FormHelperText
              hidden={!formik.touched.login || !formik.errors.login}
              error={formik.touched.login && !!formik.errors.login}
            >
              {formik.errors.login}
            </FormHelperText>
          </Grid>
          <Grid item sm={12} xs={12}>
            <InputLabel htmlFor="standard-adornment-password">Senha</InputLabel>
            <Input
              fullWidth
              inputlabelprops={{
                shrink: true,
              }}
              title="Senha"
              id="standard-adornment-password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              placeholder="•••••"
              {...formik.getFieldProps('password')}
              error={formik.touched.password && !!formik.errors.password}
              helpertext={formik.touched.password && formik.errors.password}
              endAdornment={(
                <InputAdornment
                  position="end"
                >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {
                      values.showPassword
                        ? <GoEye />
                        : <GoEyeClosed />
                    }
                  </IconButton>
                </InputAdornment>
              )}
            />
            <FormHelperText
              hidden={!formik.touched.password || !formik.errors.password}
              error={formik.touched.password && !!formik.errors.password}
            >
              {formik.errors.password}
            </FormHelperText>
            <p className={`text ${classes.labelInfo}`} style={{ marginBottom: 0 }}>
              Esqueceu sua Senha?
              {' '}
              <Link to="/recover-password" className={classes.link}>Recupere sua senha</Link>
            </p>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ButtonGradient
              type="submit"
            >
              Entrar
            </ButtonGradient>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default CheckLogin
