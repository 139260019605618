import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import useSecurity from '../shared/hooks/useSecurity'

const PrivateWrapper = () => {
  const {
    getUser,
  } = useSecurity()
  const currentUser = getUser()

  return currentUser.id ? <Outlet /> : <Navigate to="/login" />
}

export default PrivateWrapper
