import {
  useCallback,
} from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import useUserClient from '../../clients/UserClient/useUserClient'

import { useSecurityAction } from '../../store/ducks/security'

const useSecurity = () => {
  const navigate = useNavigate()
  const userClient = useUserClient()

  const {
    cleanUser,
    cleanCredential,
    cleanUserRoles,
    // setCredential,
  } = useSecurityAction()

  const {
    user,
    credential,
    userRoles,
  } = useSelector(({ security }) => ({
    user: security.user,
    credential: security.credential,
    userRoles: security.userRoles,
  }))

  const getUser = useCallback(() => user || null, [user])

  const getCredential = useCallback(() => credential || null, [credential])

  const getUserRoles = useCallback(() => userRoles || null, [userRoles])

  const getAlerts = useCallback(() => user?.alerts ?? {}, [user])

  const signin = useCallback(async (
    login,
    issuer,
    password,
    application,
    isHumanValidated,
  ) => userClient().loginUser(
    login,
    issuer,
    password,
    application,
    isHumanValidated,
  ), [userClient])

  const signout = useCallback(() => new Promise((resolve) => {
    userClient().logoutUser().then(() => {
      cleanUser()
      cleanCredential()
      cleanUserRoles()
      navigate('/login', { replace: true })
    })
    resolve()
  }), [
    navigate,
    cleanUser,
    userClient,
    cleanUserRoles,
    cleanCredential,
  ])

  return {
    getUser,
    getCredential,
    getUserRoles,
    signin,
    signout,
    getAlerts,
  }
}

export default useSecurity
