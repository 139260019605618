import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Icon from '@mui/material/Icon'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import Popover from '@mui/material/Popover'

import makeStyles from '@mui/styles/makeStyles'

import useSecurity from '../../hooks/useSecurity'

import UserOptionMenu from './UserOptionMenu'

const useStyles = makeStyles((theme) => ({
  menu: {
    '& .MuiPopover-paper': {
      width: 250,
      borderRadius: '14px',
    },
  },
  btn: {
    color: theme.palette.text.secondary,
  },
  name: {
    fontWeight: 600,
    fontSize: '1.05rem',
  },
  iconArrow: {
    fontSize: '12px',
  },
  labelInfo: {
    color: '#9c9898',
    fontSize: '12px',
  },
  modal: {
    '& .MuiPaper-root': {
      maxWidth: 600,
    },
  },
}))

const UserMenu = ({ logout }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const {
    // getName,
    avatarURL,
  } = useSecurity()

  // POPOVER
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const classes = useStyles()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Button
        className={classes.btn}
        onClick={handleClick}
        aria-controls="simple-menu"
      // title={getName()}
      >
        <Avatar src={avatarURL} />
        <Icon className={classes.iconArrow}>keyboard_arrow_down</Icon>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.menu}
        anchorOrigin={{
          vertical: 70,
          horizontal: 58,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <UserOptionMenu logout={logout} />
      </Popover>
    </>
  )
}

UserMenu.propTypes = {
  logout: PropTypes.func.isRequired,
}

export default UserMenu
