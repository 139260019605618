import React, {
  useState,
  useEffect,
} from 'react'

import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Icon from '@mui/material/Icon'
import Card from '@mui/material/Card'
import Badge from '@mui/material/Badge'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import ListItemText from '@mui/material/ListItemText'
import makeStyles from '@mui/styles/makeStyles'
import Arrow from '@mui/icons-material/KeyboardArrowRight'

import useSecurity from '../../hooks/useSecurity'
import useUtils from '../../hooks/useUtils'
import { TYPES_ALERTS } from '../../../pages/alerts/constants'

const colorNewAlert = '#3B82F6'

const useStyles = makeStyles((theme) => ({
  menu: {
    '& .MuiPopover-paper': {
      width: 310,
      maxHeight: 400,
      overflow: 'hidden',
      borderRadius: 14,
    },
  },
  btn: {
    color: theme.palette.text.secondary,
  },
  notifications: {
    '& .MuiCardContent-root': {
      overflow: 'hidden',
    },
    '& .MuiList-root': {
      width: '100%',
      height: '100%',
      maxHeight: 335,
      overflow: 'auto',
      '& .MuiButtonBase-root': {
        paddingLeft: 0,
      },
    },
  },
  notificationItem: {
    background: '#F7F7F7',
    borderRadius: 4,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderLeft: `4px solid ${colorNewAlert}`,
    marginBottom: 8,
    display: 'flex',
    alignItems: 'start',
    cursor: 'pointer',
  },
  buttonTitleNotifications: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  titleNotifications: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
  plusNotifications: {
    color: theme.palette.text.tertiary,
  },
  notificationChildren: {
    borderRadius: 4,
    alignItems: 'start',
    marginBlock: theme.spacing(1),
    backgroundColor: '#0000000a',
    '& .MuiListItemIcon-root': {
      minWidth: 35,
      justifyContent: 'center',
      marginTop: theme.spacing(1),
    },
  },
  subtitleNotification: {
    color: theme.palette.primary.footer,
  },
  emptyAlerts: {
    textAlign: 'center',
  },
  badgeNotification: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: 22,
    height: 20,
    width: 20,
    position: 'absolute',
    textAlign: 'center',
    right: 6,
  },
  colorDefaultBadge: {
    color: '#fff',
    backgroundColor: '##0E347D',
  },
  colorDangerBadge: {
    color: '#fff',
    backgroundColor: '#d32f2f',
  },
}))

const NotificationMenu = () => {
  const classes = useStyles()
  const { getAlerts } = useSecurity()
  const { getTypesNotification } = useUtils()
  const navigate = useNavigate()
  const { user } = useSelector(({ security }) => ({ user: security.user }))

  const [anchorEl, setAnchorEl] = useState(null)
  const [buttonHover, setButtonHover] = useState(false)
  const [notifications, setNotifications] = useState([])

  const toogleIcon = () => setButtonHover(true)

  const toogleIconOutlined = () => setButtonHover(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    toogleIcon()
  }

  const handleClose = () => {
    setAnchorEl(null)
    toogleIconOutlined()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleSelectNotify = (item) => {
    if (item?.url && item?.url !== '') {
      toogleIconOutlined()
      setNotifications([])
      setAnchorEl(null)
      navigate(item?.url, {
        state: {
          fromMenu: true,
          object: {
            type: item?.type ?? '',
          },
        },
      })
    }
  }

  const handleSeeAll = () => {
    toogleIconOutlined()
    setNotifications([])
    setAnchorEl(null)
    navigate('/alertas')
  }

  useEffect(() => {
    const infoAlerts = getAlerts()
    const typesNotifications = getTypesNotification()

    if (infoAlerts?.countAlerts > 0) {
      const listRender = []

      // eslint-disable-next-line array-callback-return
      infoAlerts?.listAlerts.map((alert) => {
        if (typesNotifications.includes(alert.notificationType)) {
          listRender.push({
            title: TYPES_ALERTS[alert.notificationType].text,
            description: TYPES_ALERTS[alert.notificationType].description,
            icon: TYPES_ALERTS[alert.notificationType].icon,
            count: alert.count,
            url: '/alertas',
            type: alert.notificationType,
          })
        }
      })

      setNotifications(listRender)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.alerts])

  const alertContigencyFilter = notifications?.filter(({ type }) => type === 'ContingencyMode')

  return (
    <Box>
      <Button
        title="Alertas"
        onClick={handleClick}
        className={classes.btn}
        aria-controls="simple-menu"
      >
        <Badge
          classes={{
            badge: (alertContigencyFilter?.length !== 0 && notifications?.length !== 0)
              ? classes.colorDangerBadge : classes.colorDefaultBadge,
          }}
          badgeContent={alertContigencyFilter?.length !== 0 ? '!' : notifications?.length}
        >
          {
            buttonHover
              ? <Icon color="primary">notifications</Icon>
              : <Icon>notifications_none</Icon>
          }
        </Badge>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.menu}
        anchorOrigin={{ vertical: 70, horizontal: 58 }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box>
          <Box className={classes.notifications}>
            <Card sx={{ maxWidth: 345 }}>
              <CardContent>
                <Typography gutterBottom variant="h6" color="primary">
                  Alertas
                </Typography>

                <List>
                  {notifications?.map((item, index) => (
                    <ListItem key={`notify-${index}`} className={classes.notificationItem} onClick={() => handleSelectNotify(item)}>
                      <Box mr={1}>
                        {item?.icon()}
                      </Box>

                      <Box>
                        <Box>
                          <Typography variant="subtitle1" className={classes.subtitleNotification}>
                            <b>{item?.title}</b>
                          </Typography>
                        </Box>

                        <Box>
                          <Typography variant="body1" className={classes.subtitleNotification}>
                            {item?.description}
                          </Typography>
                        </Box>
                      </Box>
                    </ListItem>
                  ))}

                  {notifications?.length === 0 && (
                    <ListItem>
                      <ListItemText
                        className={classes.emptyAlerts}
                        primary={(
                          <Typography
                            component="span"
                            variant="subtitle1"
                            className={classes.titleNotifications}
                          >
                            Não há novos alertas!
                          </Typography>
                        )}
                      />
                    </ListItem>
                  )}
                </List>

                <Box display="flex" justifyContent="center">
                  <Button variant="text" onClick={handleSeeAll}>
                    <Box display="flex" alignItems="center" justifyContent="center" gap={1} px={2}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        className={classes.buttonTitleNotifications}
                      >
                        Ver todos
                      </Typography>

                      <Arrow fontSize="medium" color="primary" />
                    </Box>
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}

export default NotificationMenu
