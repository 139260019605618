import Signin from '../pages/access/Signin'
import AccessCallback from '../pages/access/AccessCallback'
import PolicyViewer from '../pages/policy/policy-details/PolicyViewer'
import AllTicketViewer from '../pages/policy/policy-details/AllTicketViewer'
import TicketViewer from '../pages/customer-debts/debts-details/TicketViewer'
import PolicyAttachmentViewer from '../pages/policy-registration/fragments/ViewerDocument/PolicyAttachmentViewer'

const ExternalRoutes = [
  { path: '/callback', name: 'Callback', Component: AccessCallback },
  { path: '/signin', name: 'Acesso', Component: Signin },
  { path: '/boleto/:billingId', name: 'Boleto', Component: TicketViewer },
  { path: '/boleto-all/:documentNumber', name: 'Todos boletos', Component: AllTicketViewer },
  { path: '/apolices/:policyCode/documento', name: 'Documento', Component: PolicyViewer },
  { path: '/cadastro-de-apolices/:wingsDocumentId/documento', name: 'Documento', Component: PolicyAttachmentViewer },
]

export default ExternalRoutes
