import { useCallback } from 'react'
import produce from 'immer'
import { useDispatch } from 'react-redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { DEFAULT_THEME } from '../../shared/constants'

const INITIAL_STATE = {
  user: {
    color: DEFAULT_THEME,
  },
  credential: {},
}

export const Types = {
  USER: 'security/USER',
  CREDENTIAL: 'security/CREDENTIAL',
  USER_ROLES: 'security/USER_ROLES',
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.USER: {
      const { user } = action.payload

      return produce(state, (draft) => {
        draft.user = user
      })
    }

    case Types.CREDENTIAL: {
      const { credential } = action.payload

      return produce(state, (draft) => {
        draft.credential = credential
      })
    }

    case Types.USER_ROLES: {
      const { userRoles } = action.payload

      return produce(state, (draft) => {
        draft.userRoles = userRoles
      })
    }

    default: {
      return state
    }
  }
}

export const setUserStore = (user) => ({
  type: Types.USER,
  payload: { user },
})

export const useSecurityAction = () => {
  const dispatch = useDispatch()
  const setUser = useCallback(
    (user) => dispatch({
      type: Types.USER,
      payload: { user },
    }),
    [dispatch],
  )

  const setCredential = useCallback(
    (credential) => dispatch({
      type: Types.CREDENTIAL,
      payload: { credential },
    }),
    [dispatch],
  )

  const setUserRoles = useCallback(
    (userRoles) => dispatch({
      type: Types.USER_ROLES,
      payload: { userRoles },
    }),
    [dispatch],
  )

  const cleanUser = useCallback(
    () => dispatch({
      type: Types.USER,
      payload: { user: {} },
    }),
    [dispatch],
  )

  const cleanCredential = useCallback(
    () => dispatch({
      type: Types.CREDENTIAL,
      payload: { credential: {} },
    }),
    [dispatch],
  )

  const cleanUserRoles = useCallback(
    () => dispatch({
      type: Types.USER_ROLES,
      payload: { userRoles: {} },
    }),
    [dispatch],
  )

  return {
    setUser,
    setCredential,
    setUserRoles,
    cleanUser,
    cleanCredential,
    cleanUserRoles,
  }
}

export default persistReducer(
  {
    key: 'security',
    storage,
  },
  reducer,
)
