import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

import { createTheme } from '../theme'
import useSettings from '../hooks/useSettings'

const AppTheme = ({ children }) => {
  const { color } = useSettings()

  const theme = useMemo(() => createTheme(color), [color])

  useEffect(() => {
    const appTheme = (event) => {
      const { resolve } = event.detail
      resolve(theme)
    }

    window.addEventListener('app.theme', appTheme)

    return () => {
      window.removeEventListener('app.theme', appTheme)
    }
  }, [theme])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

AppTheme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
}

export default AppTheme
