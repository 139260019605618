import makeStyles from '@mui/styles/makeStyles'

export default makeStyles((theme) => ({
  root: {
    height: '90%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  image: {
    maxWidth: '100%',
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  status: {
    top: 86,
    left: 111,
    position: 'relative',
    fontSize: '2.4rem',
    fontWeight: '900',
    color: theme.palette.primary.main,
    fontFamily: 'Arial, Helvetica, sans-serif',
  },
  textError: {
    fontSize: 16,
    fontWeight: 'normal',
    color: theme.palette.text.tertiary,
  },
}))
