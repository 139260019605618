import React from 'react'
import Box from '@mui/material/Box'
import InfoIcon from '@mui/icons-material/Info'
import PercentIcon from '@mui/icons-material/Percent'
import iconAlert from '../../assets/img/icons/ico-arrow-alert.svg'
import iconUpload from '../../assets/img/icons/icon-upload.png'

export const keyRateChange = 'RateChange'
export const keyPolicyUploadId = 'PolicyUpload'
export const keyContingencyMode = 'ContingencyMode'
export const keyLimitExceededBy80 = 'LimitExceededBy80'

export const styleContingency = {
  width: 22,
  height: 22,
  color: '#d32f2f',
}

const LimitExceededBy80Style = {
  height: 20,
  width: 20,
  marginLeft: 0.4,
  marginRight: 0.4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#ffc333',
  borderRadius: '50%',
}

export const COLLECTION_ALERTS = [
  {
    value: 'All',
    text: 'Todos',
  },
  {
    value: keyRateChange,
    text: 'Alteração de Taxa',
  },
  {
    value: keyPolicyUploadId,
    text: 'Alerta de Upload',
  },
  {
    value: keyContingencyMode,
    text: 'Modo Contingência',
  },

  {
    value: keyLimitExceededBy80,
    text: 'Limite Acima de 80%',
  },
]

export const TYPES_ALERTS = {
  RateChange: {
    text: 'Alteração de Taxa',
    description: 'Existem alterações de taxa realizadas pela Pottencial.',
    icon: () => <img src={iconAlert} alt="Alteração de Taxa" />,
    viewText: 'Ver Alterações',
  },
  PolicyUpload: {
    text: 'Novo Upload de Apólice de Cliente',
    description: 'Existe upload de apólice de cliente',
    icon: () => <img src={iconUpload} height={32} width={32} style={{ marginLeft: -3 }} alt="Novo Upload de Apólice" />,
    viewText: 'Ver Upload',
  },
  ContingencyMode: {
    text: 'Modo Contigência',
    description: 'Existe solicitação que está no modo de Contingência.',
    icon: () => <InfoIcon fontSize="medium" style={styleContingency} />,
    viewText: 'Ver Todos',
  },
  LimitExceededBy80: {
    text: 'Limite Acima de 80%',
    description: 'Existem clientes com limite tomado acima de 80%.',
    icon: () => <Box {...LimitExceededBy80Style}><PercentIcon color="primary" fontSize="small" /></Box>,
    viewText: 'Ver Clientes',
  },
}

export default TYPES_ALERTS
