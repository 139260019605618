import React, {
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import { useMediaQuery } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import { useTemplateContext } from '../../contexts/TemplateContext'

const useStyles = makeStyles((theme) => ({
  margin: {
    marginBottom: theme.spacing(1),
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: -40,
    },
  },
  breadcrumb: {
    maxWidth: 500,
  },
}))

const TitlePage = ({ crumbs }) => {
  const classes = useStyles()
  const location = useLocation()
  const { titlePage } = useTemplateContext()

  const isSmDown = useMediaQuery((themeMediaQuery) => themeMediaQuery.breakpoints.down('sm'))

  const goodNews = () => {
    const goodMessage = 'Boas-vindas'
    return `${goodMessage}`
  }

  const home = useMemo(() => {
    const { pathname } = location
    return pathname === '/inicio'
  }, [location])

  const adjustedVisibleMobileTitle = (title) => isSmDown && (
    title === 'Consultar Cliente'
    || title === 'Editar Usuário'
    || title === 'Cadastro de Empresa'
    || title === 'Gerir Balanço'
    || title === 'Pré Cadastro'
    || title === 'Cadastro de Apólices')

  return (
    <>
      {!home && (
        <>
          <Grid item className={classes.margin}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="none" color="inherit" href="/">
                Página Inicial
              </Link>
              {crumbs.map(({ name, path }, key) => (name && (
                key + 1 === crumbs.length
                  ? (
                    <Typography key={key}>
                      {name}
                    </Typography>
                  ) : (
                    <Link
                      href={path}
                      key={key}
                      underline="none"
                      variant="body1"
                      color="textSecondary"
                    >
                      {name}
                    </Link>
                  )
              )))}
            </Breadcrumbs>
          </Grid>
          <Grid item className={classes.breadcrumb}>
            {titlePage && (
              <Typography
                component="span"
                style={{
                  marginLeft: adjustedVisibleMobileTitle(titlePage) ? -85 : 0,
                  fontSize: adjustedVisibleMobileTitle(titlePage) ? 30 : 40,
                  fontWeight: 700,
                }}
              >
                {titlePage}
              </Typography>
            )}
          </Grid>
        </>
      )}

      {home && (
        <Grid item>
          <Typography
            component="span"
            variant="h4"
          >
            {goodNews()}
          </Typography>
        </Grid>
      )}
    </>
  )
}

TitlePage.propTypes = {
  crumbs: PropTypes.array.isRequired,
}

export default TitlePage
