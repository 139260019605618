import React, { Suspense, useContext, useEffect } from 'react'
import {
  Route,
  Routes,
  matchPath,
  useLocation,
  useNavigate,
} from 'react-router-dom'

import MasterRoutes from './MasterRoutes'
import ExternalRoutes from './ExternalRoutes'
import SuspenseRoutes from './SuspenseRoutes'

import Login from '../shared/layouts/Login'
import Master from '../shared/layouts/Master'
import SlashScreen from '../shared/components/SlashScreen'

import PrivateWrapper from '../security/PrivateWrapper'

import CheckLogin from '../pages/check-login/CheckLogin'
import { AuthContext } from '../security/Auth'

const RoutesApp = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    maintenanceValue,
  } = useContext(AuthContext)

  const combinePaths = (parent, child) => `${parent.replace(/\/$/, '')}/${child.replace(/^\//, '')}`

  const buildPaths = (navigation, parentPath = '') => navigation.map((route) => {
    const path = combinePaths(parentPath, route.path)

    return {
      ...route,
      path,
      ...(route.routes && { routes: buildPaths(route.routes, path) }),
    }
  })

  const loadMasterRoutes = (routes) => routes
    .map((route) => [route.routes ? loadMasterRoutes(route.routes) : [], route])
    .flat(Infinity)

  const breadcrumbs = (routesMain) => routesMain.filter(
    ({ path }) => (
      matchPath({ path, end: false }, location.pathname)
    ),
  ).map(({ path, routes, ...rest }) => ({
    ...rest,
    path,
  }))

  useEffect(() => {
    if (maintenanceValue && location?.pathname !== '/error/503' && location?.pathname !== '/login') {
      navigate('/error/503')
    }
  }, [maintenanceValue, navigate, location])

  return (
    <Routes>
      <>
        <Route
          path="/login"
          element={(
            <Login>
              <CheckLogin />
            </Login>
          )}
        />

        {
          ExternalRoutes.map(({ path, Component }, key) => (
            <Route path={path} key={key} element={<Component />} />
          ))
        }
        {
          SuspenseRoutes.map(({ path, Component }, key) => (
            <Route
              path={path}
              key={key}
              element={(
                <Login>
                  <Suspense fallback={<> </>}>
                    <Component />
                  </Suspense>
                </Login>
              )}
            />
          ))
        }

        <Route path="/" element={<PrivateWrapper />}>
          {
            loadMasterRoutes(buildPaths(MasterRoutes)).map(({ path, Component, bgImage }, key) => (
              <Route
                path={path}
                key={key}
                element={(
                  <Master
                    bgImage={bgImage}
                    crumbs={breadcrumbs(MasterRoutes)}
                  >
                    <Suspense fallback={<SlashScreen />}>
                      <Component />
                    </Suspense>
                  </Master>
                )}
              />
            ))
          }
        </Route>
      </>
    </Routes>
  )
}

export default RoutesApp
