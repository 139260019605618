import React from 'react'

const ChangePassword = React.lazy(() => import('../pages/recover-password/ChangePassword'))
const RecoverPassword = React.lazy(() => import('../pages/recover-password/RecoverPassword'))

const SuspenseRoutes = [
  { path: '/recover-password', name: 'Reload', Component: RecoverPassword },
  {
    path: '/change-password', name: 'Reload', Component: ChangePassword,
  },
]

export default SuspenseRoutes
