import { useCallback } from 'react'
import axios from 'axios'

import {
  ENDORSEMENT_TYPE_CANCELLATION,
  ENDORSEMENT_TYPE_APPEAL_WRITE_OFF,
  ENDORSEMENT_TYPE_DEADLINE_EXTENSION,
  ENDORSEMENT_TYPE_INCLUSION_COVERAGE,
  ENDORSEMENT_TYPE_INCREASE_INSURED_AMOUNT,
  ENDORSEMENT_TYPE_REDUCTION_INSURED_AMOUNT,
  ENDORSEMENT_TYPE_DATA_OR_OBJECT_CORRECTION,
  ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT,
  ENDORSEMENT_TYPE_EXTENSION_REDUCTION_INSURED_AMOUNT,
  PRODUCT_JUDICIAL_DEPOSITO_RECURSAL,
  PRODUCT_FIANCA_LOCATICIA,
  PRODUCT_RESPONSABILIDADE_CIVIL,
  PRODUCT_PATRIMONIAL,
  PRODUCT_OTHER,
  PRODUCT_ENG_RISK,
  PRODUCT_JUDICIAL_LABOR,
  PRODUCT_JUDICIAL_GUARANTEE_CIVEL,
} from '../../shared/constants'

const GUARANTEE_JC = 'Judicial Cível'
const GUARANTEE_FL = 'Fiança Locatícia'
const GUARANTEE_JT = 'Judicial Trabalhista'
const CIVIL_RESPONSABILITY = 'Responsabilidade Civil'
const PATRIMONIAL = 'Patrimonial'

const JUDICIAL_EXECUÇÃO_FISCAL = 'Judicial Execução Fiscal'

const CONTRUCAO_FORNECIMENTO_SERVICO = 'contrucao-fornecimento-servico'

const usePolicyRegistrationClient = () => {
  const getTypeExecutive = useCallback((email) => axios.get(`/salesforce/comercial?user=${email}`), [])

  const getListDocuments = useCallback(async (data) => axios.post(`${process.env.REACT_APP_BFF_CLIENT}/InsurancePolicy/FindNewPolicesUpload`, data).then((response) => response.data), [])

  const getDocumentPolicy = useCallback(async (documentId) => axios.get(`${process.env.REACT_APP_BFF_CLIENT}/wings/document?wingsDocumentId=${documentId}&base64=true`), [])

  const editPolicyRegistration = useCallback(async (data) => axios.post(`${process.env.REACT_APP_BFF_CLIENT}/InsurancePolicy/PolicyEdit`, data).then((response) => response.data), [])

  const sendPolicyRegistration = useCallback(async (data) => axios.post(`${process.env.REACT_APP_BFF_CLIENT}/InsurancePolicy/PolicyRegistration`, data).then((response) => response.data), [])

  const getBroker = useCallback(async () => axios.get(`${process.env.REACT_APP_BFF_CLIENT}/Broker`), [])

  const getBrokerByDocument = useCallback(async (cnpj) => axios.get(`${process.env.REACT_APP_BFF_CLIENT}/Broker/FindByDocument?cnpj=${cnpj}`), [])

  const getInsuranceCompany = useCallback(async () => axios.get(`${process.env.REACT_APP_BFF_CLIENT}/InsuranceCompany`), [])

  const getProducts = useCallback(() => [
    { codigoModalidade: PRODUCT_FIANCA_LOCATICIA, text: GUARANTEE_FL, productKey: 'fianca-locaticia' },
    { codigoModalidade: PRODUCT_RESPONSABILIDADE_CIVIL, text: CIVIL_RESPONSABILITY, productKey: 'responsabilidade-civil' },
    { codigoModalidade: PRODUCT_PATRIMONIAL, text: PATRIMONIAL, ProductKey: 'pratrimonial' },
    { codigoModalidade: PRODUCT_ENG_RISK, productKey: 'risco-engenharia', text: 'Risco de Engenharia' },

    {
      codigoModalidade: '',
      productKey: '',
      text: 'Garantia Judicial',
      modalities: [
        { codigoModalidade: PRODUCT_JUDICIAL_GUARANTEE_CIVEL, productKey: 'judicial-civel', text: GUARANTEE_JC },
        { codigoModalidade: PRODUCT_JUDICIAL_DEPOSITO_RECURSAL, productKey: 'judicial-deposito-recursal', text: 'Judicial Depósito Recursal' },
        { codigoModalidade: PRODUCT_OTHER, productKey: 'garantia', text: JUDICIAL_EXECUÇÃO_FISCAL },
        { codigoModalidade: PRODUCT_JUDICIAL_LABOR, productKey: 'judicial-trabalhista', text: GUARANTEE_JT },
      ],
    },

    {
      codigoModalidade: '',
      productKey: '',
      text: 'Seguro Garantia',
      modalities: [
        { codigoModalidade: '8d0cc731-5fa6-438d-bae7-35a51472c9fc', productKey: 'garantia-bid', text: 'Licitante (BID)' },
        { codigoModalidade: '0040b17d-90cf-405a-8962-07cc3f19d9cb', productKey: 'garantia-performance', text: 'Performance (Construção, Fornecimento ou Prestação de Serviços)' },
        { codigoModalidade: PRODUCT_OTHER, productKey: 'garantia', text: 'Outros' },
      ],
    },
  ], [])

  const getOthersModalitiesPublic = useCallback(() => ([
    {
      text: 'Término de Obras - Banco do Brasil',
      codigoModalidade: 'cdc0bd8b-86c0-4539-b8e7-bb116a56eca2',
      productKey: 'termino-obras-bb',
    },
    {
      text: 'Construção, Fornecimento ou Prestação de Serviços - ANEEL',
      codigoModalidade: '8cd63bb7-b273-44b0-97eb-f030a0db7912',
      productKey: CONTRUCAO_FORNECIMENTO_SERVICO,
    },
    {
      text: 'Adiantamento de Pagamentos',
      codigoModalidade: '56649d39-9979-4e58-8f23-1cb7a5ae0601',
      productKey: 'garantia-adiantamento',
    },
    {
      text: 'Retenção de Pagamentos',
      codigoModalidade: '5f8a905d-1ceb-479a-9c27-5cfc7214b0ba',
      productKey: 'garantia-retencao',
    },
    {
      text: JUDICIAL_EXECUÇÃO_FISCAL,
      codigoModalidade: 'a4aa46ad-1dac-40ee-b66c-24ab5f613f32',
      productKey: 'judicial-execucao-fical',
    },
    {
      text: 'Término de Obras - Infraestrutura',
      codigoModalidade: 'd95d1fd2-3d99-466e-a1f7-4e01c2b7d992',
      productKey: 'termino-obras-infraestrutura',
    },
    {
      text: 'Garantia de Compra e venda de Energia',
      codigoModalidade: '95a2054a-1fda-4fc9-adb8-d6ba35e101bf',
      productKey: 'compra-venda-energia',
    },
    {
      text: JUDICIAL_EXECUÇÃO_FISCAL,
      codigoModalidade: '984df836-f3ca-42f3-8326-508927fd217a',
      productKey: 'judicial-fiscal',
    },
    {
      text: 'Manutenção Corretiva - Publico',
      codigoModalidade: 'df0957f1-4321-4f73-a1f4-1c9879233e2c',
      productKey: CONTRUCAO_FORNECIMENTO_SERVICO,
    },
  ]), [])

  const getOthersModalitiesPrivate = useCallback(() => ([
    {
      text: 'Adiantamento de Pagamentos',
      codigoModalidade: '18e24a3b-196a-403a-a65c-b4d6637c5411',
      productKey: 'garantia-adiantamento',
    },
    {
      text: 'Manutenção Corretiva - Privado',
      codigoModalidade: '39a2f637-abce-45e7-9411-5737bcc8ae16',
      productKey: CONTRUCAO_FORNECIMENTO_SERVICO,
    },
    {
      text: 'Retenção de Pagamentos',
      codigoModalidade: '60612140-7db7-4a5c-b86b-a2c3ed39baae',
      productKey: 'garantia-retencao',
    },
    {
      text: 'Garantia de pagamentos',
      codigoModalidade: '130dbb7c-6ef6-4e67-a779-7aae5b366d73',
      productKey: 'garantia-pagamento',
    },
    {
      text: 'Garantia de Compra e Venda de Energia',
      codigoModalidade: '64fc7e63-e9a3-418f-b82e-385fc9302c7d',
      productKey: 'garantia-compra-venda-energia',
    },
  ]), [])

  const getOthersModalities = useCallback(() => ({
    public: getOthersModalitiesPublic(),
    private: getOthersModalitiesPrivate(),
  }), [getOthersModalitiesPublic, getOthersModalitiesPrivate])

  const getTypesEndorsement = useCallback(() => [
    { id: ENDORSEMENT_TYPE_EXTENSION_INCREASE_INSURED_AMOUNT, text: 'Endosso de prorrogação de prazo com aumento de importância segurada' },
    { id: ENDORSEMENT_TYPE_INCREASE_INSURED_AMOUNT, text: 'Endosso de aumento de importância segurada' },
    { id: ENDORSEMENT_TYPE_DEADLINE_EXTENSION, text: 'Endosso de prorrogação de prazo' },
    { id: ENDORSEMENT_TYPE_CANCELLATION, text: 'Endosso de cancelamento' },
    { id: ENDORSEMENT_TYPE_EXTENSION_REDUCTION_INSURED_AMOUNT, text: 'Endosso de prorrogação de prazo com redução de importância segurada' },
    { id: ENDORSEMENT_TYPE_REDUCTION_INSURED_AMOUNT, text: 'Endosso de redução de importância segurada' },
    { id: ENDORSEMENT_TYPE_DATA_OR_OBJECT_CORRECTION, text: 'Endosso de correção de dados/objeto' },
    { id: ENDORSEMENT_TYPE_APPEAL_WRITE_OFF, text: 'Endosso de baixa recursal' },
    { id: ENDORSEMENT_TYPE_INCLUSION_COVERAGE, text: 'Endosso de inclusão de coberturas' },
  ], [])

  const getPolicyTemplate = useCallback((policy) => [
    {
      template: policy ? {
        PolicyData: {
          PolicyNumber: policy?.policyData?.policyNumber,
          MainPolicyNumber: policy?.policyData?.mainPolicyNumber,
          EndorsedNumber: policy?.policyData?.endorsedNumber,
          TypeDocument: policy?.policyData?.typeDocument,
          ProductName: policy?.policyData?.productName,
          ModalityName: policy?.policyData?.modalityName,
          ModalityOthersName: policy?.policyData?.modalityOthersName,
          TypeOthersModality: policy?.policyData?.typeOthersModality,
          EndDateValidity: policy?.policyData?.endDateValidity,
          StartDateValidity: policy?.policyData?.startDateValidity,
          DateOfIssue: policy?.policyData?.dateOfIssue,
          EndorsementType: policy?.policyData?.endorsementType,
          CodeModality: policy?.policyData?.codeModality,
          ProductKey: policy?.policyData?.productKey,
          EndorsementTypeId: policy?.policyData?.endorsementTypeId,
          EndorsementTypeText: policy?.policyData?.endorsementTypeText,
          PolicyUploadId: policy?.policyData?.policyUploadId,
          Codigo: policy?.policyData?.codigo,
          Prefixo: policy?.policyData?.prefixo,
          BrokerCnpj: policy?.policyData?.brokerCnpj,
          BrokerName: policy?.policyData?.brokerName,
          InsuranceCompanyId: policy?.policyData?.insuranceCompanyId,
          InsuranceCompanyName: policy?.policyData?.insuranceCompanyName,
          GuaranteeValue: policy?.policyData?.guaranteeValue,
        },
        PolicyHolder: {
          Name: policy?.policyHolder?.name,
          Document: policy?.policyHolder?.document,
        },
        Insured: {
          Name: policy?.insured?.name,
          Document: policy?.insured?.document,
        },
        Payment: {
          TotalPrize: policy?.payment?.totalPrize,
          PremiumPaymentType: policy?.payment?.premiumPaymentType,
          InstallmentsNumber: policy?.payment?.installmentsNumber,
        },
      } : undefined,
    },
  ], [])

  const getPolicies = useCallback((data, noLoading = false) => axios.post(`${process.env.REACT_APP_BFF_CLIENT}/insurancepolicy/lastpoliciesregistered`, data, { noLoading }), [])

  return useCallback(() => ({
    getBroker,
    getProducts,
    getOthersModalities,
    getOthersModalitiesPublic,
    getOthersModalitiesPrivate,
    getTypeExecutive,
    getDocumentPolicy,
    getPolicyTemplate,
    getListDocuments,
    getInsuranceCompany,
    getTypesEndorsement,
    editPolicyRegistration,
    sendPolicyRegistration,
    getPolicies,
    getBrokerByDocument,
  }), [
    getBroker,
    getProducts,
    getOthersModalities,
    getOthersModalitiesPublic,
    getOthersModalitiesPrivate,
    getTypeExecutive,
    getDocumentPolicy,
    getPolicyTemplate,
    getInsuranceCompany,
    getListDocuments,
    getTypesEndorsement,
    editPolicyRegistration,
    sendPolicyRegistration,
    getPolicies,
    getBrokerByDocument,
  ])
}

export default usePolicyRegistrationClient
