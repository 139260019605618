import React from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  container: {
    height: 70,
    padding: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.footer,
    '& .MuiGrid-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  info: {
    fontSize: 14,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  link: {
    paddingBottom: 2,
    position: 'relative',
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
    '&::after': {
      content: '""',
      backgroundColor: theme.palette.primary.contrastText,
      position: 'absolute',
      right: 0,
      bottom: 0,
      left: 0,
      height: 1,
      transformOrigin: '50% 50%',
      transform: 'scaleX(0)',
      transition: 'transform .7s cubic-bezier(.5,0,0,1)',
    },
    '&:hover': {
      '&::after': {
        transform: 'scaleX(1)',
      },
    },
  },
}))

const Footer = () => {
  const classes = useStyles()
  const currentYear = new Date().getFullYear()

  return (
    <Grid maxwidth="xl" container className={classes.container}>
      <Grid item xs={12}>
        <Typography className={classes.info}>
          <Box component="span" mr={1}>
            {`© ${currentYear ?? '2024'} Finlândia Todos os direitos reservados.`}
            {process.env.REACT_APP_DEVELOPMENT && process.env.REACT_APP_VERSION ? ` - ${process.env.REACT_APP_VERSION}` : ''}
          </Box>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Footer
