import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { useAlert } from '../../../../shared/components/Alert'
import ViewerDocument from '../../../../shared/components/ViewerDocument'
import usePolicyRegistrationClient from '../../../../clients/PolicyRegistrationClient/usePolicyRegistrationClient'

const PolicyAttachmentViewer = () => {
  const policyRegistrationClient = usePolicyRegistrationClient()

  const { wingsDocumentId } = useParams()
  const { addMsgDanger } = useAlert()
  const navigate = useNavigate()

  const [draftData, setDocumentData] = useState('')

  useEffect(() => {
    policyRegistrationClient().getDocumentPolicy(wingsDocumentId).then((response) => {
      setDocumentData(response?.data)
    }, (error) => {
      addMsgDanger({
        title: error?.status ?? error?.data?.status,
        message: error?.message ?? error?.data?.message ?? error?.title ?? error?.data?.title,
        action: () => navigate('/cadastro-de-apolices'),
      })
    })
  }, [wingsDocumentId, addMsgDanger, policyRegistrationClient, navigate])

  return (
    <ViewerDocument documentBase64={draftData} />
  )
}

export default PolicyAttachmentViewer
