import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import AuthContext from '../../shared/contexts/AuthContext'

const AuthProvider = ({ children }) => {
  const [user, setUserAuth] = useState({})
  const [token, setTokenAuth] = useState('')
  const [userLogin, setUserLogin] = useState('')
  const [maintenanceValue, setMaintenanceValue] = useState()

  const authProvidersField = useMemo(() => ({
    user,
    setUserAuth,
    userLogin,
    setUserLogin,
    token,
    setTokenAuth,
    setMaintenanceValue,
    maintenanceValue,
  }), [
    user,
    setUserAuth,
    userLogin,
    setUserLogin,
    token,
    setTokenAuth,
    setMaintenanceValue,
    maintenanceValue,
  ])

  return (
    <AuthContext.Provider value={authProvidersField}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.element,
}

AuthProvider.defaultProps = {
  children: undefined,
}

export default AuthProvider
