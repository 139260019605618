import { useCallback } from 'react'
import axios from 'axios'
import { isEmpty } from 'lodash'

const useUserClient = () => {
  const revalidateTokenUser = useCallback((token) => axios.get(`auth/revalidate?application=${process.env.REACT_APP_WINGS_APPLICATION}&issuer=${'W4B'}`, {
    headers: {
      Authorization:
        `Bearer ${token}`,
    },
  }).then((response) => response?.data), [])

  const loginUserName = useCallback((data) => axios.post('user/login', data)
    .then((response) => (response?.data ? response : { data: { ...response } })), [])

  const loginUser = useCallback((data) => axios.post('auth/login', data)
    .then((response) => (response?.data ? response : { data: { ...response } })), [])

  const logoutUser = useCallback(() => new Promise((resolve) => {
    axios.get('auth/logout')
      .then((response) => (response?.data ? response : { data: { ...response } }))
    resolve()
  }), [])

  const requestPassword = useCallback((data) => axios.post('auth/resetpassword', data)
    .then((response) => (response?.data ? response : { data: { ...response } })), [])

  const changePassword = useCallback((data) => axios.post('auth/changepassword', data)
    .then((response) => (response?.data ? response : { data: { ...response } })), [])

  const getUserRoles = useCallback((login, token) => axios.get('role/getuserroles', {
    headers: {
      Authorization:
        `Bearer ${token}`,
    },
    params: {
      login,
      issuer: 'W4B',
      application: 'W4B',
    },
  }).then((response) => response?.data), [])

  const getInfoUserLogged = useCallback((Email) => axios.get('/user/find', {
    params: { Email },
  }).then((response) => response?.data), [])

  const getClientByUser = useCallback((Email) => axios.get(`${process.env.REACT_APP_BFF_CLIENT}/client/policyupload/findByUser`, {
    params: { Email },
  }).then((response) => response?.data), [])

  const getClientByDocument = useCallback((CPFCNPJ) => axios.get('/user/findByDocumentClient', {
    params: { CPFCNPJ },
  }).then((response) => response?.data), [])

  const getClientByUserLogged = useCallback((Email) => getClientByUser(Email).then((response) => (
    response?.data.filter((item) => item.lead === false)
  )), [getClientByUser])

  const getAllClients = useCallback((Email) => axios.get(`/user/findByEmail/${Email}/all`)
    .then((response) => response?.data), [])

  const getClientInsuredObjectProperty = useCallback((CPFCNPJ) => axios.get(`${process.env.REACT_APP_BFF_CLIENT}/people/findByCpf`, {
    params: { CPFCNPJ },
  }).then((response) => response?.data), [])

  const createUpdateInsuredObjectProperty = useCallback(async (data) => axios.post('/people/createOrUpdate', data)
    .then((response) => (response?.data ? response : { data: { ...response } })), [])

  const subscribeNotifications = useCallback(async (data) => axios.post('/firebase/subscribe', data)
    .then((response) => (response?.data ? response : { data: { ...response } })), [])

  const maintenanceWings = useCallback(() => axios.get('/wings/platform-status')
    .then((response) => response?.data, () => 'maintenance')
    .catch((err) => err?.response), [])

  const sendIPAddress = useCallback((email, ipAddress, userType) => axios.put(`${process.env.REACT_APP_BFF_CLIENT}/user/SendIpLog?email=${email}&ipAddress=${ipAddress}&userType=${userType}`), [])

  const getAlerts = useCallback(
    (filter) => {
      const params = new URLSearchParams({
        Page: filter?.Page ?? 1,
        Rows: filter?.Rows ?? 10,
        OrderByCreate: filter?.OrderByCreate ?? 'DESC',
      })

      if (!isEmpty(filter?.DocumentClient)) {
        params.append('DocumentClient', `${filter.DocumentClient}`)
      }

      if (!isEmpty(filter?.UserEmail)) {
        params.append('SalesForceUserEmail', `${filter.UserEmail}`)
      }
      filter?.Regions?.map((item) => params.append('Regions', `${item}`))
      filter?.NotificationType?.map((item) => params.append('NotificationType', `${item}`))

      return axios.get(`${process.env.REACT_APP_BFF_CLIENT}/notification/search?${params}`)
    },
    [],
  )

  const getAlertsUnread = useCallback(
    (filter) => {
      const params = new URLSearchParams()

      if (!isEmpty(filter?.UserEmail)) {
        params.append('SalesForceUserEmail', `${filter.UserEmail}`)
      }
      filter?.Regions?.map((item) => params.append('Regions', `${item}`))

      return axios.get(`${process.env.REACT_APP_BFF_CLIENT}/notification/executive/unread?${params}`)
    },
    [],
  )

  return useCallback(() => ({
    loginUser,
    logoutUser,
    sendIPAddress,
    getUserRoles,
    getAllClients,
    loginUserName,
    changePassword,
    getClientByUser,
    requestPassword,
    maintenanceWings,
    getInfoUserLogged,
    getClientByDocument,
    getClientByUserLogged,
    subscribeNotifications,
    getClientInsuredObjectProperty,
    createUpdateInsuredObjectProperty,
    revalidateTokenUser,
    getAlerts,
    getAlertsUnread,
  }), [
    loginUser,
    logoutUser,
    sendIPAddress,
    getUserRoles,
    getAllClients,
    loginUserName,
    changePassword,
    requestPassword,
    getClientByUser,
    maintenanceWings,
    getInfoUserLogged,
    getClientByDocument,
    getClientByUserLogged,
    subscribeNotifications,
    getClientInsuredObjectProperty,
    createUpdateInsuredObjectProperty,
    revalidateTokenUser,
    getAlerts,
    getAlertsUnread,
  ])
}

export default useUserClient
