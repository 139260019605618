import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'

import useStyles from './useStyles'

const linear = 'all 110ms linear'

const useCustomStyle = makeStyles((theme) => ({
  btn: {
    color: theme.palette.secondary.button,
    border: `2px solid ${theme.palette.secondary.button}`,
    backgroundColor: '#fff',
    transition: linear,
    paddingLeft: 15,
    paddingRight: 15,

    '& svg': {
      '& path': {
        fill: theme.palette.secondary.button,
      },
    },

    '&:hover': {
      transition: linear,
      color: '#fff',
      backgroundColor: theme.palette.secondary.button,

      '& svg': {
        '& path': {
          fill: '#fff',
        },
      },
    },
  },
  btnContained: {
    transition: linear,
    color: '#fff',
    backgroundColor: theme.palette.secondary.button,

    '& svg': {
      '& path': {
        fill: '#fff',
      },
    },
  },
}))

const ButtonLight = (props) => {
  const { children } = props
  const classes = useStyles()
  const classesCustom = useCustomStyle()

  return (
    <Button
      className={`${classes.btn} ${classesCustom.btn}`}
      {...props}
    >
      {children}
    </Button>
  )
}

ButtonLight.propTypes = {
  children: PropTypes.any,
}

ButtonLight.defaultProps = {
  children: undefined,
}

export default ButtonLight
