import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    flex: '1',
    display: 'flex',
    height: 730,
  },
}))

const ViewerDocument = ({ documentBase64 }) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <embed src={`data:application/pdf;base64,${documentBase64}`} height="100%" width="100%" />
    </Box>
  )
}

ViewerDocument.propTypes = {
  documentBase64: PropTypes.string.isRequired,
}

export default ViewerDocument
