import * as React from 'react'
import PropTypes from 'prop-types'

import Menu from '@mui/material/Menu'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'

import { styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

const StyledMenu = styled((props) => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main.contrastText,
    borderRadius: 10,
    // marginTop: theme.spacing(1),
    minWidth: 295,
  },
}))

const useStyles = makeStyles((theme) => ({
  textItem: {
    color: theme.palette.text.secondary,
    '& span': {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
}))

const ButtonMenu = (props) => {
  const { title, children } = props
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <ListItemButton
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        onMouseOver={handleClick}
      >
        <ListItemText
          className={classes.textItem}
          primary={title}
        />
      </ListItemButton>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        {...props}
      >
        {children}
      </StyledMenu>
    </div>
  )
}
ButtonMenu.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
}

ButtonMenu.defaultProps = {
  children: undefined,
}

export default ButtonMenu
