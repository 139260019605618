import React from 'react'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import reportWebVitals from './reportWebVitals'

import App from './App'
import { store, persist } from './store'
import AppTheme from './shared/layouts/AppTheme'
import ErrorBoundary from './shared/components/ErrorBoundary'
import { AuthProvider } from './security/Auth'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <Provider store={store}>
    <PersistGate persistor={persist}>
      <AuthProvider>
        <ErrorBoundary>
          <AppTheme>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AppTheme>
        </ErrorBoundary>
      </AuthProvider>
    </PersistGate>
  </Provider>,
)

reportWebVitals()
