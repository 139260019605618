export const PREENCHIMENTO_OBRIGATORIO = 'Preenchimento obrigatório'
export const VALOR_INVALIDO = 'Valor inválido'
export const EMAIL_INVALIDO = 'E-mail inválido'
export const NAO_DEFINIDO = 'Não definido'
export const DATA_FINAL_MAIOR_INICIAL = 'A data final deve ser maior que a data inicial.'
export const VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR = 'Verifique os campos em destaque antes de prosseguir.'
export const ERRO_SENHA = 'Senha incorreta.'
export const ERRO_USUARIO_INEXISTENTE = 'Usuário não encontrado.'
export const INCREASE_IS_REDUCTION_GUARANTEE = '* Favor informar novo valor de importância segurada de acordo com tipo de endosso informado.'
export const EQUAL_TO_POLICY_HOLDER = '* O CNPJ do segurado não pode ser igual ao do Tomador.'
export const BLOCKED_USER_MESSAGE = 'Entre em contato com o TI através do e-mail: suporte.sistemas@finlandiaseguros.com.br'

export const CAMPO_MAIOR_ZERO = (campo) => {
  const campoConcat = campo ? ` ${campo}` : ''
  return `O campo${campoConcat} deverá ser maior que zero.`
}
export const DESCRIPTION_TIME_TICKET = (campo) => {
  const campoConcat = campo ? ` ${campo}` : ''
  return `O boleto pode demorar${campoConcat} minutos para ser disponibilizado após a emissão da apólice.`
}

const Global = {
  NAO_DEFINIDO,
  EMAIL_INVALIDO,
  VALOR_INVALIDO,
  CAMPO_MAIOR_ZERO,
  BLOCKED_USER_MESSAGE,
  EQUAL_TO_POLICY_HOLDER,
  DATA_FINAL_MAIOR_INICIAL,
  INCREASE_IS_REDUCTION_GUARANTEE,
  PREENCHIMENTO_OBRIGATORIO,
  VERIFIQUE_CAMPOS_DESTAQUE_ANTES_PROSSEGUIR,
  ERRO_SENHA,
  ERRO_USUARIO_INEXISTENTE,
  DESCRIPTION_TIME_TICKET,
}

export default Global
